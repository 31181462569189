import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';

import ButtonBack from '../Util/ButtonBack';
import ButtonEdit from '../Util/ButtonEdit';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class GridViewComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                        key={x.user_id}>

                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Status:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                        {x.is_active === 'Y' ?
                            <b>Active</b>
                            :
                            x.is_active === 'N' ?
                                <b>Inactive</b>
                            :
                                <b>Undefined</b>
                        }
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Full Name:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.full_name}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Email:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.email}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Created By:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.created_by}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Created At:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.created_at}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Last Updated By:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.updated_by}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Last Updated At:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.updated_at}</b>
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4}
                    className={classes.box}>

                    <ButtonEdit
                        className={classes.button}
                        onClick={this.props.action}
                    />

                    <ButtonBack
                        className={classes.button}
                        onClick={this.props.action2}
                    />
                </Box>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(GridViewComponent);
import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import axios from "axios";
import { Config } from '../../../Config';
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import JobStatusDialogComponent from './Component/JobStatusDialogComponent';
import HeaderComponent from './Component/HeaderComponent';
import TableComponent from './Component/TableComponent';

import BackdropComponent from '../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class EspJobStatus extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,

            jtu_data: [],
            job_no: '',
            h_dialog: false,

            backdrop: false,
        }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        this.setState({
            data: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,
        }, () => {
            this.fetchData(this.state.page, this.state.rowsPerPage)
        })
    }

    fetchData = (page, rowsPerPage) => {
        this.setState({ backdrop: true })

        axios.get(Config() + '/surveyjob/jobstatus/esp/page=' + parseInt(page + 1) + '/count=' + rowsPerPage,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        page: page,
                        count: response.data[3]['totalcount'],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ backdrop: false })
            })
    }

    //sorting function for table
    sortHandler = (clickedColumn) => {
        const { column, data, direction } = this.state

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'asc',
            })
            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'asc' ? 'desc' : 'asc',
        })
    }

    //handle page changes and set user defined page number and rows per page
    pageHandler = (newPage) => {
        this.setState({
            page: newPage,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(newPage, this.state.rowsPerPage)
        }
        else {
            this.searchHandler(newPage, this.state.rowsPerPage)
        }
    }

    //handle changes in rows display per page
    rowHandler = (value) => {
        this.setState({
            rowsPerPage: value,
            page: 0,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(0, value)
        }
        else {
            this.searchHandler(0, value)
        }
    }

    checkJobStatusHandler = (ljs_no, esp_no) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/jobstatus/esp/get_jtu_data',
            {
                'ljs_no': ljs_no,
                'esp_no': esp_no
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        jtu_data: response.data[1],
                        job_no: response.data[2],
                        loading: false,
                        h_dialog: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
        this.setState({
            loading: false
        })
    }

    closeJobStatusDialog = () => {
        this.setState({ h_dialog: false })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    goView = (form_id) => {
        sessionStorage.setItem('form_id_review', form_id)
        this.props.history.push({
            pathname: '/ui/EspJobStatus'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <JobStatusDialogComponent
                    action={this.goView}
                    open={this.state.h_dialog}
                    onClose={this.closeJobStatusDialog}
                    data={this.state.jtu_data}
                    job_no={this.state.job_no} />

                <HeaderComponent />
                <br />
                <TableComponent
                alert={this.state.alert}
                error_msg={this.state.error_msg}
                data={this.state.data}
                action={this.checkJobStatusHandler}
                actionSort={this.sortHandler}
                column={this.state.column}
                direction={this.state.direction}
                count={this.state.count}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.pageHandler}
                onChangeRowsPerPage={this.rowHandler} />
        </div>
        )
    }
}

export default withStyles(styles)(EspJobStatus);
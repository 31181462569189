import React from 'react';

import TextField from '@material-ui/core/TextField';

class TelTextFormInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant='outlined'
                margin="normal"
                fullWidth
                multiline
                type="tel"
                inputProps={this.props.inputProps}
                inputRef={this.props.inputRef}
                InputLabelProps={this.props.InputLabelProps}
                defaultValue={this.props.defaultValue}
            />
        )
    }
}

export default TelTextFormInput;
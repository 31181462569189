import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';
import GenericRequiredTextInput from '../../Util/GenericRequiredTextInput';
import GenericTextFormInput from '../../Util/GenericTextFormInput';
import TelTextFormInput from '../../Util/TelTextFormInput';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    }
})

class Grid1Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.reference}>

                        <Grid item xs={12} sm={4}>
                            Licensed Surveyor Reference:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.reference}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Job Type:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.job_code} {x.job_desc}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Job Description:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.job_desc_form}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            JTU Reference:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.jtu_file}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            District:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.dist_code} {x.dist_name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Location:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.location}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </div>
        )
    }
}

class Grid2Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.reference}>

                        <Grid item xs={12} sm={4}>
                            Name:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.address}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Country:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.country}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            City:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.city}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            State:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.state}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Postcode:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.postcode}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Telephone Number:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.tel_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Email Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.email}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4} style={{ textAlign: 'center' }}>
                    <ButtonIdleComponent
                        color="primary"
                        className={this.props.classNameButton}
                        onClick={this.props.onClick}
                        name="Edit Landowner"
                    />
                </Box>
            </div>
        )
    }
}

class Grid2ComponentEdit extends React.Component {
    constructor() {
        super()
        this.state = {
            // shrink: true,
            alert: false,
            error_msg: ''
        }
    }

    onChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = () => {

        this.setState({ loading: true })

        //eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.input_name.value === '' || this.input_address.value === '') {
            this.setState({
                error_name: this.input_name.value ? false : true,
                error_address: this.input_address.value ? false : true,
            })
            console.log("Please fill in the required input/s.")
            this.errorHandler("Please fill in the required input/s.")
        }else {
            if (this.input_email.value !== '') {
                if (!re.test(this.input_email.value)) {
                    this.setState({
                        error_name: this.input_name.value ? false : true,
                        error_address: this.input_address.value ? false : true,
                        error_email: true,
                    })
                    console.log("Invalid format for Email Address.")
                    this.errorHandler("Invalid format for Email Address.")
                }else {
                    this.props.action(
                        this.input_name.value,
                        this.input_address.value,
                        this.input_country.value,
                        this.input_city.value,
                        this.input_state.value,
                        this.input_postcode.value,
                        this.input_tel_no.value,
                        this.input_email.value
                    )
                }
            }else {
                this.props.action(
                    this.input_name.value,
                    this.input_address.value,
                    this.input_country.value,
                    this.input_city.value,
                    this.input_state.value,
                    this.input_postcode.value,
                    this.input_tel_no.value,
                    this.input_email.value
                )
            }
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <div>
                {this.state.alert ?
                    <Alert severity="error">
                        {this.state.error_msg}
                </Alert>
                    :
                    null}

                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.reference}>

                        <Grid item xs={12} sm={4}>
                            Name*:
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                error={this.state.error_name}
                                // label="Name"
                                name="input_name"
                                inputProps={{ maxLength: 250 }}
                                inputRef={node => (this.input_name = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                                defaultValue={x.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Address*:
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                error={this.state.error_address}
                                // label="Address"
                                name="input_address"
                                inputProps={{ maxLength: 450 }}
                                inputRef={node => (this.input_address = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                                defaultValue={x.address}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Country:
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GenericTextFormInput
                                // error={this.state.error_country}
                                // label="Country"
                                name="input_country"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_country = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                                defaultValue={x.country}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            City:
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GenericTextFormInput
                                error={this.state.error_city}
                                // label="City"
                                name="input_city"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_city = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                                defaultValue={x.city}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            State:
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GenericTextFormInput
                                error={this.state.error_state}
                                // label="State"
                                name="input_state"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_state = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                                defaultValue={x.state}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Postcode:
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GenericTextFormInput
                                error={this.state.error_postcode}
                                // label="Postcode"
                                name="input_postcode"
                                inputProps={{ maxLength: 10 }}
                                inputRef={node => (this.input_postcode = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                                defaultValue={x.postcode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Telephone Number:
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TelTextFormInput
                                error={this.state.error_tel_no}
                                // label="Telephone Number"
                                name="input_tel_no"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_tel_no = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                                defaultValue={x.tel_no}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Email Address:
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GenericTextFormInput
                                error={this.state.error_email}
                                // label="Email Address"
                                name="input_email"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_email = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                                defaultValue={x.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
            ))}

            {this.props.loading ?
                <Box pt={4} style={{ textAlign: 'center' }}>
                    <ButtonLoadComponent
                        color="primary"
                        className={this.props.classNameButton}
                        name="Updating..."
                    />

                    <ButtonLoadComponent
                        color="secondary"
                        className={this.props.classNameButton}
                        name="..."
                    />
                </Box>
                :
                <Box pt={4} style={{ textAlign: 'center' }}>
                    <ButtonIdleComponent
                        color="primary"
                        className={this.props.classNameButton}
                        onClick={this.submitHandler}
                        name="Update"
                    />
                    <ButtonIdleComponent
                        color="secondary"
                        className={this.props.classNameButton}
                        onClick={this.props.onClick}
                        name="Cancel"
                    />
                </Box>
                }
            </div>
        )
    }
}

class ButtonIdleComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color={this.props.color}
                className={this.props.className}
                onClick={this.props.onClick}
            >
                {this.props.name}
            </Button>
        )
    }
}

class ButtonLoadComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                {this.props.name}
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class Section1Component extends React.Component {
    constructor() {
        super()
        this.state = {
            viewState: true
        }
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState
        })
    }

    submitHandler = (
        name, address, country, city, state, postcode, tel_no, email
    ) => {

        this.props.action(
            name, address, country, city, state, postcode, tel_no, email
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 1 - Survey Job Details
                    </Typography>
                </AccordionSummary>
                <Divider />

                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Survey Job Details
                    </Typography>
                </AccordionDetails>

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Grid1Component
                            className={classes.padding}
                            data={this.props.data}
                        />
                    </AccordionDetails>
                }

                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Landowner / Representative Details
                        </Typography>
                </AccordionDetails>

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>

                        {this.state.viewState === true ?
                            <React.Fragment>
                                <Grid2Component
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    onClick={this.viewStateHandler}
                                />
                            </React.Fragment>

                            :

                            <React.Fragment>
                                <Grid2ComponentEdit
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    loading={this.props.loading}
                                    action={this.submitHandler}
                                    onClick={this.viewStateHandler}
                                />
                            </React.Fragment>
                        }
                    </AccordionDetails>
                    }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);
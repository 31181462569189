import React from 'react';
import { withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

import image from '../../Resources/Image1.png'

const styles = theme => ({
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing(4),
    },
    nestedTwice: {
        paddingLeft: theme.spacing(6)
    },
    image: {
        width: 100,
        marginLeft: 65,
        position: 'relative',
    },
    text: {
        textAlign: 'center',
        paddingBottom: 20
    }
});

class DrawerComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            visible: false
        }
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <div className={classes.toolbar} />

                <div onMouseEnter={e => this.setState({ visible: true })}
                    onMouseLeave={e => this.setState({ visible: false })}>
                    <a href="/ui/StaffHome">
                        <img src={image} alt="Sabah Surveyors Board"
                            className={classes.image}></img>
                    </a>
                    <br />
                    <br />
                    <Collapse in={this.state.visible}>
                        <Grow in={this.state.visible}>
                            <Typography variant="h6"
                                className={classes.text}>
                                Sabah Surveyors Board
                                </Typography>
                        </Grow>
                    </Collapse>
                    <br />
                </div>
                <Divider />

                <List component="nav">
                    {/* Survey Job Section */}
                    <ListItem button onClick={() => this.setState({ nested_1: !this.state.nested_1 })}>
                        <ListItemText primary="Survey Job" />
                        {this.state.nested_1 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_1}>
                        <List component="nav">
                            <ListItem button
                                selected={this.state.nested_1_1} onClick={() =>
                                    this.setState({ nested_1_1: !this.state.nested_1_1 })}
                                className={classes.nested}>
                                <ListItemText primary="Survey Job List" />
                                {this.state.nested_1_1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_1_1}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/StaffCadastralJobList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Cadastral" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/StaffEngineeringJobList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Engineering" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/StaffPrivatisedJobList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="JTU Privatised" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem button
                                selected={this.state.nested_1_2} onClick={() =>
                                    this.setState({ nested_1_2: !this.state.nested_1_2 })}
                                className={classes.nested}>
                                <ListItemText primary="File Search" />
                                {this.state.nested_1_2 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_1_2}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/PaymentVoucherSearch' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Payment Voucher" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/AdminChargeSearch' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Admin Charge Receipt" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/PenyataBayaranSearch' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Penyata Bayaran" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button
                                selected={this.state.nested_1_3} onClick={() =>
                                    this.setState({ nested_1_3: !this.state.nested_1_3 })}
                                className={classes.nested}>
                                <ListItemText primary="Survey Portal Job Status" />
                                {this.state.nested_1_3 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_1_3}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/RspJobStatus' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="RSP Job Status" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/EspJobStatus' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="ESP Job Status" />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                    </Collapse>

                    {/* Licensed Surveyor Section */}
                    <ListItem button onClick={() => this.setState({ nested_2: !this.state.nested_2 })}>
                        <ListItemText primary="Licensed Surveyor" />
                        {this.state.nested_2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_2}>
                        <List component="nav">
                            <ListItem button
                                selected={this.state.nested_2_1} onClick={() =>
                                    this.setState({ nested_2_1: !this.state.nested_2_1 })}
                                className={classes.nested}>
                                <ListItemText primary="Register New Account" />
                                {this.state.nested_2_1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_2_1}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/LicensedSurveyorRegister' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Licensed Surveyor" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/LSStaffRegister' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="LS Staff" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem button
                                selected={this.state.nested_2_2} onClick={() =>
                                    this.setState({ nested_2_2: !this.state.nested_2_2 })}
                                className={classes.nested}>
                                <ListItemText primary="List" />
                                {this.state.nested_2_2 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_2_2}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/LicensedSurveyorList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Licensed Surveyor" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/LSStaffList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="LS Staff" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem button
                                onClick={() => this.props.history.push({ pathname: '/ui/LicensedSurveyorAdd' })}
                                className={classes.nested}>
                                <ListItemText primary="Add New Licensed Surveyor" />
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* Survey Technician Section */}
                    <ListItem button onClick={() => this.setState({ nested_3: !this.state.nested_3 })}>
                        <ListItemText primary="Survey Technician" />
                        {this.state.nested_3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_3}>
                        <List component="nav">
                            <ListItem button
                                selected={this.state.nested_3_1} onClick={() =>
                                    this.setState({ nested_3_1: !this.state.nested_3_1 })}
                                className={classes.nested}>
                                <ListItemText primary="Technician List" />
                                {this.state.nested_3_1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_3_1}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/StaffCadastralTechnicianList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Cadastral" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/StaffEngineeringTechnicianList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Engineering" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem button
                                onClick={() => this.props.history.push({ pathname: '/ui/StaffOldSTSTechnicianList' })}
                                className={classes.nested}>
                                <ListItemText primary="Technician List Old STS Format" />
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* Articled Graduate Section */}
                    <List component="nav">
                        <ListItem button
                            onClick={() => this.props.history.push({ pathname: '/ui/StaffArticledGraduateList' })}>
                            <ListItemText primary="Articled Graduate" />
                        </ListItem>

                    </List>



                    {/* <ListItem button onClick={() => this.setState({ nested_4: !this.state.nested_4 })}>
                        <ListItemText primary="Articled Graduate" />
                        {this.state.nested_4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_4}>
                        <List component="nav">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="New Articled Graduate Application" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Articled Graduate List" />
                            </ListItem>
                        </List>
                    </Collapse> */}

                    {/* CPD Section */}
                    <ListItem button onClick={() => this.setState({ nested_5: !this.state.nested_5 })}>
                        <ListItemText primary="CPD" />
                        {this.state.nested_5 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_5}>
                        <List component="nav">
                            <ListItem button
                                onClick={() => this.props.history.push({ pathname: '/ui/StaffCPDList' })}
                                className={classes.nested}>
                                <ListItemText primary="CPD List" />
                            </ListItem>
                            <ListItem button
                                onClick={() => this.props.history.push({ pathname: '/ui/StaffCPDLogbook' })}
                                className={classes.nested}>
                                <ListItemText primary="CPD Logbook" />
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* Reports Section */}
                    <ListItem button onClick={() => this.setState({ nested_6: !this.state.nested_6 })}>
                        <ListItemText primary="Reports" />
                        {this.state.nested_6 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_6}>
                        <List component="nav">
                            <ListItem button selected={this.state.nested_6_1} onClick={() =>
                                    this.setState({ nested_6_1: !this.state.nested_6_1 })}
                                className={classes.nested}>
                                <ListItemText primary="Cadastral" />
                                    {this.state.nested_6_1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_6_1}>
                                <List component="nav">
                                    <ListItem button 
                                        onClick={() => this.props.history.push({ pathname: '/ui/Cadastral/Deposit' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Deposit" />
                                    </ListItem>
                                    <ListItem button 
                                        onClick={() => this.props.history.push({ pathname: '/ui/Cadastral/Claim' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Claim" />
                                    </ListItem>
                                    <ListItem button 
                                        onClick={() => this.props.history.push({ pathname: '/ui/Cadastral/Levy' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Admin Charge/Levy" />
                                    </ListItem>
                                    <ListItem button 
                                        onClick={() => this.props.history.push({ pathname: '/ui/Cadastral/Refund' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Refund" />
                                    </ListItem>
                                    <ListItem button className={classes.nestedTwice}>
                                        <ListItemText primary="Job Progress" />
                                    </ListItem>
                                    <ListItem button className={classes.nestedTwice}>
                                        <ListItemText primary="Statement of Accounts" />
                                    </ListItem>
                                    {/* <ListItem button className={classes.nestedTwice}>
                                        <ListItemText primary="Reminder Report" />
                                    </ListItem> */}
                                </List>
                            </Collapse>
                            <ListItem button selected={this.state.nested_6_2} onClick={() =>
                                    this.setState({ nested_6_2: !this.state.nested_6_2 })}
                                className={classes.nested}>
                                <ListItemText primary="Engineering" />
                                    {this.state.nested_6_2 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_6_2}>
                                <List component="nav">
                                    <ListItem button 
                                        onClick={() => this.props.history.push({ pathname: '/ui/Engineering/Deposit' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Deposit" />
                                    </ListItem>
                                    <ListItem button 
                                        onClick={() => this.props.history.push({ pathname: '/ui/Engineering/Claim' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Claim" />
                                    </ListItem>
                                    <ListItem button 
                                        onClick={() => this.props.history.push({ pathname: '/ui/Engineering/Levy' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Admin Charge/Levy" />
                                    </ListItem>
                                    <ListItem button 
                                        onClick={() => this.props.history.push({ pathname: '/ui/Engineering/Refund' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Refund" />
                                    </ListItem>
                                    <ListItem button className={classes.nestedTwice}>
                                        <ListItemText primary="Job Progress" />
                                    </ListItem>
                                    <ListItem button className={classes.nestedTwice}>
                                        <ListItemText primary="Statement of Accounts" />
                                    </ListItem>
                                    {/* <ListItem button className={classes.nestedTwice}>
                                        <ListItemText primary="Reminder Report" />
                                    </ListItem> */}
                                </List>
                            </Collapse>
                            <ListItem button className={classes.nested}
                                onClick={() =>
                                    this.props.history.push({ pathname: '/ui/TextToBank' })}>
                                <ListItemText primary="Text To Bank Converter" />
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* System Parameters Section */}
                    <ListItem button onClick={() => this.setState({ nested_7: !this.state.nested_7 })}>
                        <ListItemText primary="Reference Parameters" />
                        {this.state.nested_7 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_7}>
                        <List component="nav">
                            <ListItem button
                                        selected={this.state.nested_7_1} onClick={() =>
                                            this.setState({ nested_7_1: !this.state.nested_7_1 })}
                                        className={classes.nested}>
                                        <ListItemText primary="Users" />
                                        {this.state.nested_7_1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_7_1}>
                                    <List component="nav">
                                        <ListItem button
                                            onClick={() => this.props.history.push({ pathname: '/ui/UserList' })}
                                            className={classes.nestedTwice}>
                                            <ListItemText primary="User List" />
                                        </ListItem>
                                        <ListItem button
                                            onClick={() => this.props.history.push({ pathname: '/ui/UserAdd' })}
                                            className={classes.nestedTwice}>
                                            <ListItemText primary="Add New LJS User" />
                                        </ListItem>
                                    </List>
                            </Collapse>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Job Types" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Job Stages" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="CPD" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="JUD" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="District" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Banks" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="The Undersign" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Document" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Swift Code" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="System Parameter" />
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* Claim Section
                    <ListItem button
                        onClick={() => this.props.history.push({ pathname: '/ui/ClaimList' })}
                    >
                        <ListItemText primary="Claims" />
                    </ListItem> */}
                </List>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(DrawerComponent))
import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class LicensedSurveyorAdd extends React.Component {
    constructor() {
        super()
        this.state = {
            swift_code_data: [],
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        axios.get(Config() + '/licensedsurveyor/add/initialize',
            {
                withCredentials: true
            })
            .then(response => {
                this.setState({
                    swift_code_data: response.data
                })
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    addHandler = (
        ls_no, ls_title, ls_name, ic_no, co_name, ls_status,
        email, phone, mobile, fax, addr1, addr2, addr3, addr4,
        paddr1, paddr2, paddr3, paddr4, lic_no, svc_tax,
        gl_acctno, ls_acct_no, ls_bank, ls_bank_branch, payee_ind
    ) => {
        if (ls_no === '' || ls_name === '' || ls_status === '') {
            this.errorHandler("Please fill in the required input/s.")
        }
        else {
            this.setState({ loading: true })
            axios.post(Config() + '/licensedsurveyor/add/',
                {
                    'ls_no': ls_no,
                    'ls_title': ls_title,
                    'ls_name': ls_name,
                    'ic_no': ic_no,
                    'co_name': co_name,
                    'ls_status': ls_status,
                    'email': email,
                    'phone': phone,
                    'mobile': mobile,
                    'fax': fax,
                    'addr1': addr1,
                    'addr2': addr2,
                    'addr3': addr3,
                    'addr4': addr4,
                    'paddr1': paddr1,
                    'paddr2': paddr2,
                    'paddr3': paddr3,
                    'paddr4': paddr4,
                    'lic_no': lic_no,
                    'svc_tax': svc_tax,
                    'gl_acctno': gl_acctno,
                    'ls_acct_no': ls_acct_no,
                    'ls_bank': ls_bank,
                    'ls_bank_branch': ls_bank_branch,
                    'payee_ind': payee_ind
                },
                {
                    withCredentials: true
                })
                .then(response => {
                    if (response.data === "Success") {
                        this.props.history.push({
                            pathname: '/ui/LicensedSurveyorList'
                        })
                    }
                    else {
                        this.errorHandler(response.data)
                    }
                })
                .catch(error => {
                    if (!error.response) {
                        this.errorHandler("Code issue probably.")
                    }
                    else {
                        if (ResponseStatusCheck(error)) {
                            //If Session Expired
                            if (ResponseStatusCheck(error) === 401) {
                                console.log("Display some window here.")
                            }
                            else {
                                this.errorHandler(ResponseStatusCheck(error))
                            }
                        }
                        else {
                            this.errorHandler("Something went very wrong!")
                        }
                    }
                })
                this.setState({ 
                    loading: false
                })
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <HeaderComponent />

                <FormComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg}
                    loading={this.state.loading}
                    swift_code_data={this.state.swift_code_data}
                    action={this.addHandler} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(LicensedSurveyorAdd));
import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../Util/RequiredTextInput';
import RequiredSelectTextInput from '../Util/RequiredSelectTextInput';
import ButtonCancel from '../Util/ButtonCancel';
import ButtonEdit from '../Util/ButtonEdit';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class GridEditComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            status_data: [
                {
                    'key': 'Y',
                    'value': 'Y',
                    'text': 'Active'
                },
                {
                    'key': 'N',
                    'value': 'N',
                    'text': 'Inactive'
                }
            ],
            input_active_status: '',
        }
    }

    componentDidMount() {
        this.setState({
            input_active_status: this.props.data[0]['is_active'],
        })
    }

    onChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = () => {
        this.props.action3(
            this.state.input_active_status,
            this.input_full_name.value,
            this.input_email.value
        )
    }

    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                        key={x.full_name}>

                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Status: *
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredSelectTextInput
                                error={this.state.error_active_status}
                                name="input_active_status"
                                value={this.state.input_active_status}
                                onChange={this.onChangeHandler}
                                data={this.state.status_data}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Full Name:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                name="input_full_name"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_full_name = node)}
                                defaultValue={x.full_name}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Email:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                name="input_email"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_email = node)}
                                defaultValue={x.email}
                            />
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4}
                    className={classes.box}>

                    <ButtonEdit
                        className={classes.button}
                        onClick={this.submitHandler}
                    />

                    <ButtonCancel
                        className={classes.button}
                        onClick={this.props.action4}
                    />
                </Box>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(GridEditComponent);
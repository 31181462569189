import React from 'react';
import { withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

import image from '../../Resources/Image1.png'

const styles = theme => ({
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing(4),
    },
    nestedTwice: {
        paddingLeft: theme.spacing(6)
    },
    image: {
        width: 100,
        marginLeft: 65,
        position: 'relative'
    },
    text: {
        textAlign: 'center',
        paddingBottom: 20
    }
});

class DrawerComponent extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    render() {
        const { classes } = this.props
        if (localStorage.getItem('perm') === "2") {
            var url = "/ui/LSStaffHome"
        }
        else {
            url = "/ui/SurveyorHome"
        }

        return (
            <div>
                <div className={classes.toolbar} />

                <div onMouseEnter={e => this.setState({ visible: true })}
                    onMouseLeave={e => this.setState({ visible: false })}>
                    <a href={url}>
                        <img src={image} alt="Sabah Surveyors Board"
                            className={classes.image}></img></a>
                    <br />
                    <br />
                    <Collapse in={this.state.visible}>
                        <Grow in={this.state.visible}>
                            <Typography variant="h6"
                                className={classes.text}>
                                Sabah Surveyors Board
                            </Typography>
                        </Grow>
                    </Collapse>
                    <br />
                </div>
                <Divider />

                <List component="nav">
                    {/* Survey Job Section */}
                    <ListItem button
                        onClick={() => this.setState({ nested_1: !this.state.nested_1 })}>
                        <ListItemText primary="Survey Job" />
                        {this.state.nested_1 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_1}>
                        <List component="nav">
                            <ListItem button
                                onClick={() => this.setState({ nested_1_1: !this.state.nested_1_1 })}
                                className={classes.nested}>
                                <ListItemText primary="Create New Application" />
                                {this.state.nested_1_1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_1_1}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => {
                                            sessionStorage.clear()
                                            sessionStorage.setItem('form_id', 'new')
                                            //force a rerender of the component for new form
                                            window.location.assign('/ui/CadastralForm')
                                        }
                                        }
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Cadastral Survey" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => {
                                            sessionStorage.clear()
                                            sessionStorage.setItem('form_id', 'new')
                                            //force a rerender of the component for new form
                                            window.location.assign('/ui/EngineeringForm')
                                        }
                                        }
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Engineering Survey" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => {
                                            sessionStorage.clear()
                                            sessionStorage.setItem('form_id', 'new')
                                            sessionStorage.setItem("10K", "Y")
                                            //force a rerender of the component for new form
                                            window.location.assign('/ui/EngineeringForm')
                                        }
                                        }
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Engineering Survey (<10K and No Deposition)" />
                                    </ListItem>
                                    {/* <ListItem button
                                        onClick={() => {
                                            sessionStorage.clear()
                                            sessionStorage.setItem('form_id', 'new')
                                            //force a rerender of the component for new form
                                            window.location.assign('/ui/PrivatisedForm')
                                        }
                                        }
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="JTU Privatize Survey" />
                                    </ListItem> */}
                                </List>
                            </Collapse>
                            <ListItem button
                                onClick={() => this.setState({ nested_1_2: !this.state.nested_1_2 })}
                                className={classes.nested}>
                                <ListItemText primary="List" />
                                {this.state.nested_1_1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_1_2}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/CadastralJobList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Cadastral Survey" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/EngineeringJobList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="Engineering Survey" />
                                    </ListItem>
                                    {/* <ListItem button
                                        onClick={() => this.props.history.push({ pathname: '/ui/PrivatisedJobList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="JTU Privatised Survey" />
                                    </ListItem> */}
                                </List>
                            </Collapse>
                        </List>
                    </Collapse>

                    {/* Survey Technician Section */}
                    <ListItem button onClick={() => this.setState({ nested_2: !this.state.nested_2 })}>
                        <ListItemText primary="Survey Technician" />
                        {this.state.nested_2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_2}>
                        <List component="nav">
                            <ListItem button
                                onClick={() => this.setState({ nested_2_1: !this.state.nested_2_1 })}
                                className={classes.nested}>
                                <ListItemText primary="Cadastral" />
                            </ListItem>
                            <Collapse in={this.state.nested_2_1}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => {
                                            sessionStorage.clear()
                                            sessionStorage.setItem('technician_id', 'new')
                                            //force a rerender of the component for new form
                                            window.location.assign('/ui/CadastralTechnicianForm')
                                        }
                                        }
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="New Application" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() =>
                                            this.props.history.push({ pathname: '/ui/CadastralTechnicianList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="List" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button
                                onClick={() => this.setState({ nested_2_2: !this.state.nested_2_2 })}
                                className={classes.nested}>
                                <ListItemText primary="Engineering" />
                            </ListItem>
                            <Collapse in={this.state.nested_2_2}>
                                <List component="nav">
                                    <ListItem button
                                        onClick={() => {
                                            sessionStorage.clear()
                                            sessionStorage.setItem('technician_id', 'new')
                                            //force a rerender of the component for new form
                                            window.location.assign('/ui/EngineeringTechnicianForm')
                                        }
                                        }
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="New Application" />
                                    </ListItem>
                                    <ListItem button
                                        onClick={() =>
                                            this.props.history.push({ pathname: '/ui/EngineeringTechnicianList' })}
                                        className={classes.nestedTwice}>
                                        <ListItemText primary="List" />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                    </Collapse>

                    {/* Articled Graduate Section */}
                    <ListItem button onClick={() => this.setState({ nested_3: !this.state.nested_3 })}>
                        <ListItemText primary="Articled Graduate" />
                        {this.state.nested_3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_3}>
                        <List component="nav">
                            <ListItem button
                                onClick={() => {
                                    sessionStorage.clear()
                                    sessionStorage.setItem('articledgraduate_id', 'new')
                                    //force a rerender of the component for new form
                                    window.location.assign('/ui/ArticledGraduateForm')
                                }
                                }
                                className={classes.nested}>
                                <ListItemText primary="New Application" />
                            </ListItem>
                            <ListItem button
                                onClick={() => this.props.history.push({ pathname: '/ui/ArticledGraduateList' })}
                                className={classes.nested}>
                                <ListItemText primary="List" />
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* CPD Section */}
                    <ListItem button onClick={() => this.setState({ nested_4: !this.state.nested_4 })}>
                        <ListItemText primary="CPD" />
                        {this.state.nested_4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_4}>
                        <List component="nav">
                            <ListItem button
                                onClick={() => {
                                    sessionStorage.clear()
                                    sessionStorage.setItem('cpd_id', 'new')
                                    //force a rerender of the component for new form
                                    window.location.assign('/ui/CPDForm')
                                }
                                }
                                className={classes.nested}>
                                <ListItemText primary="New Application" />
                            </ListItem>

                            <ListItem button
                                onClick={() => {
                                    this.props.history.push({ pathname: '/ui/CPDList' })
                                }}
                                className={classes.nested}>
                                <ListItemText primary="List" />
                            </ListItem>

                            <ListItem button
                                onClick={() => {
                                    this.props.history.push({ pathname: '/ui/CPDLogbook' })
                                }}
                                className={classes.nested}>
                                <ListItemText primary="CPD Logbook" />
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* Licensed Surveyor Reports Section */}
                    <ListItem button onClick={() => this.setState({ nested_5: !this.state.nested_5 })}>
                    <ListItemText primary="Reports" />
                        {this.state.nested_5 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.nested_5}>
                        <List component="nav">
                            <ListItem button
                                        selected={this.state.nested_5_1} onClick={() =>
                                            this.setState({ nested_5_1: !this.state.nested_5_1 })}
                                        className={classes.nested}>
                                        <ListItemText primary="Cadastral" />
                                        {this.state.nested_5_1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_5_1}>
                                    <List component="nav">
                                        <ListItem button
                                            onClick={() => this.props.history.push({ pathname: '/ui/Cadastral/JobStatement' })}
                                            className={classes.nestedTwice}>
                                            <ListItemText primary="Job Statement " />
                                        </ListItem>
                                    </List>
                            </Collapse>
                            <ListItem button
                                        selected={this.state.nested_5_2} onClick={() =>
                                            this.setState({ nested_5_2: !this.state.nested_5_2 })}
                                        className={classes.nested}>
                                        <ListItemText primary="Engineering" />
                                        {this.state.nested_5_2 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.nested_5_2}>
                                    <List component="nav">
                                        <ListItem button
                                            onClick={() => this.props.history.push({ pathname: '/ui/Engineering/JobStatement' })}
                                            className={classes.nestedTwice}>
                                            <ListItemText primary="Job Statement " />
                                        </ListItem>
                                    </List>
                            </Collapse>
                        </List>
                    </Collapse>
                </List>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(DrawerComponent))
import React from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    }
})

class SurveyPortalComponent extends React.Component {
    render() {
        const { classes } = this.props
        
        return (
            <div>
                <br />
                <Typography variant="h5">
                    Survey Portal Cadastral Job Status Updates
                </Typography>
                <br/>
                <Typography>
                    Job No: <b>{this.props.job_no}</b>
                </Typography>
                <Box pt={4}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Status Code / Status Description / Status Date / Updated On / Updated By"
                        multiline
                        variant="outlined"
                        rowStatus s={4}
                        style={{ width: '100%', resize: 'vertical' }}
                        // style={{ width: '95%', resize: 'vertical', overflow: 'auto' }}
                        value={this.props.data.map(x => ('\n'+x.status_code+' / '+x.status_desc+' / '+x.status_date+' / '+x.updated_at+' / '+x.updated_by))}>
                    </TextField>
                </Box>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(SurveyPortalComponent);
import React from 'react';

import { withRouter } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../Config';
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
import Section1Component from './Component/Section1Component';
import Section2Component from './Component/Section2Component';
import Section3Component from './Component/Section3Component';
import Section4Component from './Component/Section4Component';
import Section5Component from './Component/Section5Component';
import Section6Component from './Component/Section6Component';
import Review1Component from './Component/Review1Component';
import Review2Component from './Component/Review2Component';
import File1Component from './Component/File1Component';
import File2Component from './Component/File2Component';
import File3Component from './Component/File3Component';
import ESPComponent from './Component/ESPComponent';
import SurveyPortalComponent from './Component/SurveyPortalComponent';
import ButtonComponent from './Component/ButtonComponent';
import DialogComponent from './Component/DialogComponent';
import DialogPaymentComponent from './Component/DialogPaymentComponent';
import DialogESPComponent from './Component/DialogESPComponent';

import BackdropComponent from '../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class EngineeringSurveyJobReview extends React.Component {
    constructor() {
        super()
        this.state = {
            stage_data: [],
            section1_data: [],
            section2_1_data: [],
            section2_2_data: [],
            section3_data: [],
            pt_data: [],
            section4_data: [],
            amount_data: [],
            section5_data: [],
            receipt_data: [],
            sa_data: [],
            history_data: [],
            job_no: '',
            esp_data: [],
            claim_data: [],
            jtu_data: [],

            curr_stage: '',
            backdrop: false,

            dialog_1: false,
            dialog_2: false,
            p_dialog_1: false,
            p_dialog_2: false,
            e_dialog: false,
            h_dialog: false,
        }
    }

    componentDidMount() {
        this.setState({ backdrop: true })
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        axios.put(Config() + '/surveyjob/engineering/view/get',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        stage_data: response.data[1],
                        section1_data: response.data[2],
                        section2_1_data: response.data[3],
                        section2_2_data: response.data[4],
                        section3_data: response.data[5],
                        pt_data: response.data[6],
                        amount_data: response.data[7],
                        section4_data: response.data[8],
                        section5_data: response.data[9],
                        receipt_data: response.data[10],
                        sa_data: response.data[11],
                        job_no: response.data[12],
                        esp_data: response.data[13],
                        claim_data: response.data[14],
                        jtu_data: response.data[15],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    console.log(error)
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    openFirstCheckerDialog = () => {
        this.setState({ dialog_1: true })
    }

    closeFirstCheckerDialog = () => {
        this.setState({ dialog_1: false })
    }

    submitFirstChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/update/checker1',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    openSecondCheckerDialog = () => {
        this.setState({ dialog_2: true })
    }

    closeSecondCheckerDialog = () => {
        this.setState({ dialog_2: false })
    }

    approveSecondChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/update/checker2/approve',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    console.log(error)
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    rejectSecondChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/update/checker2/reject',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generatePT = () => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/pt/add',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generatePT_Add = () => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/pt/add/additional',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    openFirstCheckerPaymentDialog = () => {
        this.setState({ p_dialog_1: true })
    }

    closeFirstCheckerPaymentDialog = () => {
        this.setState({ p_dialog_1: false })
    }

    submitFirstCheckerPayment = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/update/payment/checker1',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    openSecondCheckerPaymentDialog = () => {
        this.setState({ p_dialog_2: true })
    }

    closeSecondCheckerPaymentDialog = () => {
        this.setState({ p_dialog_2: false })
    }

    approveSecondCheckerPayment = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/update/payment/checker2/approve',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    console.log(error)
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    rejectSecondCheckerPayment = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/update/payment/checker2/reject',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generateReceipt = () => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/receipt/add',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generateSA = () => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/sa/add',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    openESPDialog = () => {
        this.setState({ e_dialog: true })
    }

    closeESPDialog = () => {
        this.setState({ e_dialog: false })
    }

    addESP = (esp_no, esp_date) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/esp/add',
        {
            'form_id': sessionStorage.getItem('form_id_review'),
            'esp_no': esp_no,
            'esp_date': esp_date
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    updateESP = (esp_no, esp_date, ljs3_date, ljs7_date, remark) => {
        this.setState({ loading: true })

        axios.put(Config() + '/surveyjob/engineering/review/esp/update',
        {
            'form_id': sessionStorage.getItem('form_id_review'),
            'esp_no': esp_no,
            'remark': remark,
            'esp_date': esp_date,
            'ljs3_date': ljs3_date,
            'ljs7_date': ljs7_date,
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    updateLandowner = (
        name, address, country, city, state, postcode, tel_no, email
    ) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/engineering/review/update/landowner',
        {
            'form_id': sessionStorage.getItem('form_id_review'),
            'name': name,
            'address': address,
            'country': country,
            'city': city,
            'state': state,
            'postcode': postcode,
            'tel_no': tel_no,
            'email': email
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    goTableSummary = () => {
        this.props.history.push({
            pathname: '/ui/StaffEngineeringTableSummaryView'
        })
    }

    goClaimList = () => {
        this.props.history.push({
            pathname: '/ui/StaffEngineeringClaimList'
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/StaffEngineeringJobList'
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                    open1={this.state.dialog_1}
                    onClose1={this.closeFirstCheckerDialog}
                    action1={this.submitFirstChecker}
                    open2={this.state.dialog_2}
                    onClose2={this.closeSecondCheckerDialog}
                    action2={this.approveSecondChecker}
                    action3={this.rejectSecondChecker}
                />

                <DialogPaymentComponent
                    open1={this.state.p_dialog_1}
                    onClose1={this.closeFirstCheckerPaymentDialog}
                    action1={this.submitFirstCheckerPayment}
                    open2={this.state.p_dialog_2}
                    onClose2={this.closeSecondCheckerPaymentDialog}
                    action2={this.approveSecondCheckerPayment}
                    action3={this.rejectSecondCheckerPayment}
                />

                <DialogESPComponent
                    open={this.state.e_dialog}
                    onClose={this.closeESPDialog}
                    action={this.addESP}
                />

                <HeaderComponent />

                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    {!this.state.job_no ?
                        <Typography variant="h5">
                            Job No: None
                    </Typography>

                        :

                        <Typography variant="h5">
                            Job No: <b>{this.state.job_no}</b>
                        </Typography>

                    }

                    {this.state.stage_data.length === 0 ?
                        <Typography variant="h5">
                            Current Stage: N/A
                    </Typography>

                        :

                        <Typography variant="h5">
                            Current Stage: <b>{this.state.stage_data[0]['stage_desc']}</b>
                        </Typography>

                    }

                    <br />

                    <Typography variant="h4">
                        Survey Job Information
                    </Typography>

                    <br />

                    <Section1Component
                        data={this.state.section1_data} 
                        action={this.updateLandowner}
                    />

                    <Section2Component
                        data={this.state.section2_1_data}
                        data2={this.state.section2_2_data}
                        action={this.goTableSummary}
                    />

                    <Section3Component
                        data={this.state.section3_data} />

                    <Review1Component
                        data={this.state.section1_data} />
                    <br />

                    <File1Component
                        data={this.state.pt_data}
                        action={this.generatePT}
                        action2={this.generatePT_Add}
                        loading={this.state.loading} />

                    <br />

                    <Typography variant="h4">
                        Payment Information
                    </Typography>

                    <br />

                    <Section4Component
                        amount={this.state.amount_data}
                        data={this.state.section4_data}
                        pt_data={this.state.pt_data} />

                    <Section5Component
                        data={this.state.section5_data} />

                    <Review2Component
                        data={this.state.section4_data} />
                    <br />

                    <File2Component
                        data={this.state.receipt_data}
                        action={this.generateReceipt}
                        loading={this.state.loading} />

                    <File3Component
                        data={this.state.sa_data}
                        action={this.generateSA}
                        loading={this.state.loading} />

                    <ESPComponent
                        data={this.state.esp_data}
                        action={this.updateESP} />

                    <SurveyPortalComponent
                        job_no={this.state.job_no}
                        data={this.state.jtu_data} />
                    <br />

                    <Typography variant="h4">
                        Claim Information
                    </Typography>

                    <br />

                    <Section6Component
                        action={this.goClaimList}
                        data={this.state.claim_data}
                    />

                    <br />

                </Paper>

                <ButtonComponent
                    stage_data={this.state.stage_data}
                    action={this.goBack}
                    action1={this.openFirstCheckerDialog}
                    action2={this.openSecondCheckerDialog}
                    action3={this.openFirstCheckerPaymentDialog}
                    action4={this.openSecondCheckerPaymentDialog}
                    action5={this.openESPDialog} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(EngineeringSurveyJobReview));
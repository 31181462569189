import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        maxWidth: 600,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
    input: {
        display: 'none',
    },
    progressbar: {
        height: 10,
        borderRadius: 5
    },
    radiogroup: {
        margin: 20
    }
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Next
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class ProgressBarComponent extends React.Component {
    render() {
        return (
            <Box display="flex" alignItems="center">
                <Box minWidth={35} style={{ textAlign: 'center' }}>
                { this.props.claimstatus === 'Draft' ? 
                    'Current draft claim '
                    :
                    'Current claim status'
                }
                </Box>

                <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" className={this.props.className}
                        value={this.props.value} />
                </Box>

                <Box minWidth={35}>
                    <Typography variant="body2" color="primary">{this.props.value}%</Typography>
                </Box>
            </Box>
        )
    }
}

class RadioGroupComponent extends React.Component {
    render() {
        return (
            <FormControl component="fieldset" className={this.props.className}>
                <FormLabel component="legend">Percentage</FormLabel>
                { this.props.claimstatus === 'Draft' ? 
                    <RadioGroup value={this.props.percentage} onChange={this.props.onChange}>
                    <FormControlLabel value="95" control={<Radio />} label="95%"
                    disabled={parseInt(this.props.value) < 95 ? false : true} />
                </RadioGroup>
            :
                    <RadioGroup value={this.props.percentage} onChange={this.props.onChange}>
                        <FormControlLabel value="95" control={<Radio />} label="95%"
                        disabled={parseInt(this.props.value) < 95 ? false : true} />
                    </RadioGroup>
                }
            </FormControl>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            percentage: '',
            current_pc: 0,
            claim_status: ''
        }
    }

    onChangeHandler = (event) => {
        this.setState({
            percentage: event.target.value
        })
    }

    submitHandler = () => {
        if (this.state.percentage === ''){
            sessionStorage.setItem('claim_percent', sessionStorage.getItem('claim_percent_view'))
        }else{
            if (this.props.claimind === 'Adv'){
                this.state.current_pc = this.state.percentage - 10
            }else{
                console.log("__props.claimpc = ", this.props.claimpc)
                if (this.props.claimpc === 10 || this.props.claimpc === 55){
                    this.state.current_pc = this.state.percentage - 10
                    console.log("_1_current_pc = ", this.state.current_pc)
                }else{
                    this.state.current_pc = this.state.percentage 
                    console.log("_2_current_pc = ", this.state.current_pc)
                }
            }
            console.log("__current_pc = ", this.state.current_pc)
            sessionStorage.setItem('claim_percent', this.state.current_pc)
        }
        this.props.action()
    }

    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                {this.state.alert ?
                    <Alert severity="error">
                        {this.state.error_msg}
                    </Alert>
                    :
                    null}

                <br />

                <ProgressBarComponent className={classes.progressbar}
                    value={this.props.value} />

                <br />

                <RadioGroupComponent className={classes.radiogroup}
                        percentage={this.state.percentage}
                        claimstatus={this.props.claimstatus}
                        claimind={this.props.claimind}
                        value={this.props.value}
                    onChange={this.onChangeHandler} />

                <Divider />

                <br />

                <Typography variant="body2">
                    *The breakdown of claim details will be displayed on the next page.
                </Typography>

                <br />

                <Box pt={4} style={{ textAlign: 'center' }}>
                    <NextIdleButton className={classes.button}
                    onClick={this.submitHandler} />
                    <BackIdleButton className={classes.button}
                    onClick={this.props.action2} />
                </Box>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);
import React from 'react'

import { NumericFormat } from 'react-number-format';
import { CSVLink } from "react-csv";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
// Icon for Export buttons
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 1000
    },
    tablecell: {
        fontWeight: 'bold',
        width: '10%'
    }
})

const headers = [
    { label: "Date", key: "levy_date" },
    { label: "LJS No.", key: "job_no" },
    { label: "Licensed Surveyor", key: "ls_name" },
    { label: "Ref. No.", key: "ls_reference" },
    { label: "CP No.", key: "cp_no" },
    { label: "Admin Charge Amount (RM)", key: "levy_amount" }
  ];

class ExportCSVButtonComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled={this.props.disabled}
                className={this.props.className}
                startIcon={<GetAppRoundedIcon />}
                onClick={this.props.onClick}
            >
                Export CSV
            </Button>
        )
    }
}

class ExportPDFButtonComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled={this.props.disabled}
                className={this.props.className}
                startIcon={<PictureAsPdfRoundedIcon />}
                onClick={this.props.onClick}
            >
                Export PDF
            </Button>
        )
    }
}

class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
        this.csvLinkEl = React.createRef();
    }
    
    sortHandler = (clickedColumn) => () => {
        this.props.actionSort(clickedColumn)
    }

    onChangePage = (event, newPage) => {
        this.props.onChangePage(newPage)
    }

    onChangeRowsPerPage = (event) => {
        this.props.onChangeRowsPerPage(event.target.value)
    }

    getFileName = () => {
        let d = new Date();
        let dformat = `${d.getDate()}${d.getMonth()+1}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}`;
        console.log("getCurrentDate : ", dformat);
        return "EngineeringLevyReport_" + dformat + ".csv";
    }

    exportCSV = async () => {
        const data = this.props.data
        this.setState({ data: data }, () => {
            setTimeout(() => {
            this.csvLinkEl.current.link.click();
            });
        });
    }

    exportPDF = () => {
        this.props.action(this.props.data2, this.props.startdate, this.props.enddate)
    }

    render() {
        const { classes } = this.props
        
        let sumLevy = 0.0
        this.props.data2.map(x => ( sumLevy += x.levy_amount ))
        
        const cellHeader = [
            {
                id: "levy_date",
                label: "DATE"
            },
            {
                id: "job_no",
                label: "LJS NO."
            },
            {
                id: "ls_name",
                label: "LICENSED SURVEYOR"
            },
            {
                id: "ls_reference",
                label: "REF NO"
            },
            {
                id: "cp_no",
                label: "CP NO"
            },
            {
                id: "levy_amount",
                label: "ADMIN CHARGE AMOUNT (RM)"
            }
        ]

        return (
            
            <Paper className={classes.paper}>
                {this.props.alert ?
                    <Alert severity="error">
                        {this.props.error_msg}
                    </Alert>
                    :
                    null}

                {!this.props.data.length ?
                <div align="right">
                    <ExportCSVButtonComponent onClick={this.exportCSV} disabled={true}/>
                    <CSVLink
                        headers={headers}
                        filename={this.getFileName()}
                        data={this.props.data2}
                        ref={this.csvLinkEl}
                    />
                    &nbsp;
                    <ExportPDFButtonComponent onClick={this.exportPDF} disabled={true}/>
                </div>
                :
                <div align="right">
                    <ExportCSVButtonComponent onClick={this.exportCSV} disabled={false}/>
                    <CSVLink
                        headers={headers}
                        filename={this.getFileName()}
                        data={this.props.data2}
                        ref={this.csvLinkEl}
                    />
                    &nbsp;
                    <ExportPDFButtonComponent onClick={this.exportPDF} disabled={false}/>
                </div>
                }
                <br/>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {cellHeader.map(cellHeader => (
                                    <TableCell className={classes.tablecell}
                                        key={cellHeader.id}
                                        padding="default"
                                        // align="center"
                                        sortDirection={this.props.column === cellHeader.id ? this.props.direction : null}                                        
                                    >
                                        <TableSortLabel
                                            active={this.props.column === cellHeader.id}
                                            direction={this.props.direction}
                                            onClick={this.sortHandler(cellHeader.id)}
                                        >
                                            {cellHeader.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        {!this.props.data.length ?
                            <TableBody>
                                <TableRow>
                                <TableCell align="center"
                                        colSpan={6}>
                                        No data to display.
                                </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {this.props.data.map(x => (
                                    <TableRow key={x.form_id}>
                                        <TableCell>{x.levy_date}</TableCell>
                                        <TableCell>{x.job_no}</TableCell>
                                        <TableCell>{x.ls_name}</TableCell>
                                        <TableCell>{x.ls_reference}</TableCell>
                                        <TableCell>{x.cp_no}</TableCell>
                                        <TableCell align="right">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={x.levy_amount? x.levy_amount.toFixed(2) : '0.00' } 
                                                thousandSeparator=',' 
                                                decimalSeparator='.' 
                                            />
                                        </TableCell>
                                    </TableRow> 
                                ))}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TableContainer align="right">
                <TableBody>
                    <TableRow key="totalEngineeringLevy">
                        <TableCell align="right" style={{fontSize: 20, fontWeight: 'bold'}}>TOTAL ADMIN CHARGE :</TableCell>
                        <TableCell align="right" style={{fontSize: 20, fontWeight: 'bold'}}>
                            <NumericFormat 
                                displayType="text"
                                prefix='RM '
                                value={sumLevy.toFixed(2)} 
                                thousandSeparator=',' 
                                decimalSeparator='.' 
                            />
                        </TableCell>
                    </TableRow> 

                </TableBody>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={this.props.count}
                    rowsPerPage={this.props.rowsPerPage}
                    page={this.props.page}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TableComponent);
import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import axios from 'axios';
import { Config } from '../../../Config'
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import SearchPanelComponent from './Component/SearchPanelComponent';
import TableComponent from './Component/TableComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    }
})

class CadastralJobStatement extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            data2: [],
            select_array: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,
            
            backdrop: false
        }

        this.ComponentUI = React.createRef()
    }


    componentDidMount() {
        this.init()
    }

    init = () => {
        this.setState({
            data: [],
            data2: [],
            job_deposit: [],
            job_claim: [],
            job_refund: [],
            select_array: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,

            ls_ref: '',
            ljs_ref: '',
            rsp_no: '',
            job_type: '',
            job_status: '',

            alert: false,
            error_msg: ''
        }, () => {
            this.fetchData(this.state.page, this.state.rowsPerPage)
        })
    }

    fetchData = (page, rowsPerPage) => {
            this.setState({ backdrop: true })

        // axios.get(Config() + '/ls_report/cadastral/jobstatement/get',
        axios.get(Config() + '/ls_report/cadastral/jobstatement/page=' + parseInt(page + 1) + '/count=' + rowsPerPage,
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.setState({
                    data: response.data[1],
                    page: page,
                    count: response.data[3]['totalcount'],
                    select_array: response.data[4],

                    backdrop: false
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }


    //sorting function for table
    sortHandler = (clickedColumn) => {
        const { column, data, direction } = this.state

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'asc',
            })
            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'asc' ? 'desc' : 'asc',
        })
    }

    //handle page changes and set user defined page number and rows per page
    pageHandler = (newPage) => {
        this.setState({
            page: newPage,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(newPage, this.state.rowsPerPage)
        }
        else {
            this.searchHandler(newPage, this.state.rowsPerPage)
        }
    }

    //handle changes in rows display per page
    rowHandler = (value) => {
        this.setState({
            rowsPerPage: value,
            page: 0,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(0, value)
        }
        else {
            this.searchHandler(0, value)
        }
    }

    submitHandler = (
        page, rowsPerPage, ls_ref, ljs_ref, rsp_no //, job_type, job_status
    ) => {
        if (ls_ref === '' && ljs_ref === '' && rsp_no === '') {//&& job_type === '' && job_status === '') {
            this.errorHandler("Please provide some value to search .")
        }

        else {
            this.setState({
                ls_ref: ls_ref,
                ljs_ref: ljs_ref,
                rsp_no: rsp_no,
                // job_type: job_type,
                // job_status: job_status
            }, () => {
                this.searchHandler(page, rowsPerPage)
            })
        }
    }

    searchHandler = (page, rowsPerPage) => {
        this.setState({ backdrop: true, alert: false })
        axios.put(Config() + '/ls_report/cadastral/jobstatement/search/page=' + parseInt(page + 1) + '/count=' + rowsPerPage,
            {
                'ls_ref': this.state.ls_ref,
                'ljs_ref': this.state.ljs_ref,
                'rsp_no': this.state.rsp_no,
                // 'job_type': this.state.job_type,
                // 'job_status': this.state.job_status,
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        page: page,
                        count: response.data[3]['totalcount'],
                        data2: response.data[4],
                        job_deposit: response.data[5],
                        job_claim: response.data[6],
                        job_refund: response.data[7],

                        backdrop: false,
                        searchIndicator: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generatePDF = (job_data, job_deposit, job_claim, job_refund) => {
        axios.put(Config() + '/staff_report/cadastral/jobstatement/generate',
        {
            'job_data': job_data,
            'job_deposit': job_deposit,
            'job_claim': job_claim,
            'job_refund': job_refund
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.openFile(response.data[1])
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    openFile = (value) => {
        window.open(value, "_blank")
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>

                <HeaderComponent />
                <SearchPanelComponent
                action={this.submitHandler}
                action2={this.init}
                jobtype={this.state.select_array} />
                <br />
                <TableComponent 
                alert={this.state.alert}
                error_msg={this.state.error_msg}
                data={this.state.data}
                data2={this.state.data2}
                jobdeposit={this.state.job_deposit}
                jobclaim={this.state.job_claim}
                jobrefund={this.state.job_refund}
                action={this.generatePDF}
                // />
                actionSort={this.sortHandler}
                column={this.state.column}
                direction={this.state.direction}
                count={this.state.count}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.pageHandler}
                onChangeRowsPerPage={this.rowHandler}
                />

{/* 
                <br />
                <FormComponent
                    action={this.addData}
                    ref={this.ComponentUI} /> */}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CadastralJobStatement));
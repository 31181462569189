import React from 'react'

import { withRouter } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import DialogComponent from './Component/DialogComponent';
import HeaderComponent from './Component/HeaderComponent';
import Section1Component from './Component/Section1Component';
import Section2Component from './Component/Section2Component';
import Section3Component from './Component/Section3Component';
import Section4Component from './Component/Section4Component';
import Section5Component from './Component/Section5Component';
import Section6Component from './Component/Section6Component';
import Review1Component from './Component/Review1Component';
import Review2Component from './Component/Review2Component';
import File1Component from './Component/File1Component';
import File2Component from './Component/File2Component';
import File3Component from './Component/File3Component';
import ButtonComponent from './Component/ButtonComponent';
import RSPComponent from './Component/RSPComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class CadastralJobView extends React.Component {
    constructor() {
        super()
        this.state = {
            stage_data: [],
            section1_data: [],
            section2_1_data: [],
            section2_2_data: [],
            section3_data: [],
            pt_data: [],
            section4_data: [],
            amount_data: [],
            section5_data: [],
            receipt_data: [],
            sa_data: [],
            job_no: '',
            rsp_data: [],
            claim_indicator: [],

            backdrop: false,
            loading: false,
            dialog: false
        }
    }

    componentDidMount() {
        //if there is no form id value in session storage
        // if (sessionStorage.getItem('form_id') === '' || sessionStorage.getItem('form_id') === undefined ||
        //     sessionStorage.getItem('form_id') === null) {
        //     this.props.history.push({
        //         pathname: '/ui/SurveyorHome'
        //     })
        // }
        // else {
        //     this.setState({ backdrop: true })
        //     this.fetchData()
        // }
        this.setState({ backdrop: true })
        this.fetchData()
    }

    fetchData = () => {
        axios.put(Config() + '/ls_surveyjob/cadastral/view/get',
            {
                // 'form_id': sessionStorage.getItem('form_id')
                'form_id': sessionStorage.getItem('form_id_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    console.log(response.data[1])
                    this.setState({
                        stage_data: response.data[1],
                        section1_data: response.data[2],
                        section2_1_data: response.data[3],
                        section2_2_data: response.data[4],
                        section3_data: response.data[5],
                        pt_data: response.data[6],
                        amount_data: response.data[7],
                        section4_data: response.data[8],
                        section5_data: response.data[9],
                        receipt_data: response.data[10],
                        sa_data: response.data[11],
                        job_no: response.data[12],
                        rsp_data: response.data[13],
                        claim_indicator: response.data[14],
                        job_no2: response.data[12],

                        backdrop: false
                    })
                }else{
                    console.log(response.data[1])
                    this.setState({
                        stage_data: response.data[1],
                        section1_data: response.data[2],
                        job_no: response.data[3],
                        section2_1_data: response.data[4],
                        claim_indicator: 'First',
                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    goTableSummary = () => {
        this.props.history.push({
            pathname: '/ui/CadastralTableSummaryView'
        })
    }

    goClaimList = () => {
        this.props.history.push({
            pathname: '/ui/CadastralClaimList'
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/CadastralJobList'
        })
    }

    goPayment = () => {
        this.props.history.push({
            pathname: '/ui/CadastralPaymentForm'
        })
    }

    openDialog = () => {
        this.setState({ dialog: true })
    }

    closeDialog = () => {
        this.setState({ dialog: false })
    }

    resubmissionHandler = () => {
        this.setState({ loading: true })
        if (localStorage.getItem('perm') === "2") {
            var user_perm = "2" 
        }
        else {
            user_perm = "1"
        }

        axios.post(Config() + '/ls_surveyjob/cadastral/resubmission/add',
            {
                'form_id': sessionStorage.getItem('form_id_view'),
                'access_level': user_perm
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    sessionStorage.clear()
                    sessionStorage.setItem('form_id', response.data[1])

                    this.props.history.push({
                        pathname: '/ui/CadastralApplicationSummary'
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    goClaim = () => {
        if (localStorage.getItem('perm') === "2") {
            var user_perm = "2" 
        }
        else {
            user_perm = "1"
        }
        this.props.history.push({
            pathname: '/ui/CadastralClaimSelector'
        })
    }

    goModifyApplication = () => {
        let form_id = sessionStorage.getItem('form_id_view')
        sessionStorage.clear()
        sessionStorage.setItem('form_id', form_id)
        this.props.history.push({
            pathname: '/ui/CadastralApplicationSummary'
        })
    }

    goModifyPayment = () => {
        this.props.history.push({
            pathname: '/ui/CadastralPaymentForm'
        })
    }

    goCancellation = () => {
        //Remove the claim percent value 
        sessionStorage.removeItem('claim_percent')
        this.props.history.push({
            pathname: '/ui/CadastralCancellationForm'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value,
            dialog: false
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                    onClose={this.closeDialog}
                    open={this.state.dialog}
                    action={this.resubmissionHandler}
                    action2={this.closeDialog} />

                <HeaderComponent />

                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    {!this.state.job_no ?
                        <Typography variant="h5">
                            Job No: None
                        </Typography>
                        :
                        <Typography variant="h5">
                            Job No: <b>{this.state.job_no}</b>
                        </Typography>}

                    <br />

                    <Typography variant="h4">
                        Survey Job Information
                    </Typography>
                    <br />

                    <Section1Component
                        data={this.state.section1_data} />

                    <br/>
                    <Section2Component
                        data={this.state.section2_1_data}
                        data2={this.state.section2_2_data}
                        action={this.goTableSummary} />

                    <Section3Component
                        data={this.state.section3_data} />

                    <Review1Component
                        data={this.state.section1_data} />
                    <br />

                    <File1Component
                        data={this.state.pt_data} />

                    <br />

                    <Typography variant="h4">
                        Payment Information
                    </Typography>

                    <br />

                    <Section4Component
                        amount={this.state.amount_data}
                        data={this.state.section4_data} />

                    <Section5Component
                        data={this.state.section5_data} />

                    <Review2Component
                        data={this.state.section4_data} />
                    <br />

                    <File2Component
                        data={this.state.receipt_data} />

                    <File3Component
                        data={this.state.sa_data} />

                    <RSPComponent
                        data={this.state.rsp_data} />

                    <br />

                    <Typography variant="h4">
                        Claim Information
                    </Typography>

                    <br />

                    <Section6Component
                    action={this.goClaimList} 
                    />

                    <br />

                </Paper>

                <ButtonComponent
                    stage={this.state.stage_data}
                    claim_indicator={this.state.claim_indicator}
                    action={this.goBack}
                    action2={this.goPayment}
                    action3={this.openDialog}
                    action4={this.goClaim}
                    action5={this.goModifyApplication}
                    action6={this.goModifyPayment}
                    action7={this.goCancellation}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CadastralJobView));
import React from 'react';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../Config';
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class UserAdd extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            backdrop: false
        }
    }

    addData = (
        login_name, password, full_name, email
    ) => {
        axios.post(Config() + '/user/add/',
        {
            'user_name': login_name,
            'password': password,
            'full_name': full_name,
            'email': email
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data === "Success") {
                this.props.history.push({ pathname: '/ui/UserList' })
            }
            else {
                this.ComponentUI.current.errorHandler("Username already exist.")
            }
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <FormComponent
                    action={this.addData}
                    ref={this.ComponentUI} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(UserAdd));
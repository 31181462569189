import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class JobStatusDialogComponent extends React.Component {

    openHandler = (form_id) => {
        this.props.action(form_id)
    }

    render() {
        const { classes } = this.props
        return (
            <Dialog
                onClose={this.props.onClose}
                open={this.props.open}>

                <DialogTitle>
                    Survey Portal RSP Job Status
                </DialogTitle>
                <DialogContent>
                    {!this.props.data.length ?
                        null
                        :
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell align='center'>LJS No.</TableCell>
                                <TableCell align="center">RSP No.</TableCell>
                                <TableCell align="center">Status Code</TableCell>
                                <TableCell align="center">Status Description</TableCell>
                                <TableCell align="center">Status Date</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.data.map(x => (
                                    <TableRow key={x.ljs_key}>
                                        <TableCell align="center">
                                            {x.ljs_no}
                                        </TableCell>
                                        <TableCell align="center">
                                            {x.rsp_no}
                                        </TableCell>
                                        <TableCell align="center">
                                            {x.status_code}
                                        </TableCell>
                                        <TableCell align="center">
                                            {x.status_desc}
                                        </TableCell>
                                        <TableCell align="center">
                                            {x.status_date}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(styles, { withTheme: true })(JobStatusDialogComponent);
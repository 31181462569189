import React from 'react'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

import image from '../../../Resources/Image1.png';
import LandingImage from '../../../Resources/eConnectLoginLJS6a.png';

//--- Stylesheet ---
const styles = theme => ({  //Copied from Surveyor/Auth/Component/GridComponent.js as reference
    root: {
        height: '100vh'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    controlLabel: {
        display: 'flex',
        textAlign: 'left', //force control label to align left
    },
    image: {
        backgroundImage: `url(${LandingImage})`,
        backgroundPosition: 'bottom',
        backgroundSize: '100% 100%', 
    },
})

class RequiredTextInput extends React.Component {
    render() {
        return (
            <TextField
                name="input_username"
                label="Username"
                required
                autoFocus
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 35 }}
                fullWidth
                helperText={this.props.helperText}
                error={this.props.error}
                onChange={this.props.onChange}
            />
        )
    }
}

class ButtonIdleComponent extends React.Component {
    render() {
        return (
            <Button 
                type="submit"
                variant="contained" 
                color="primary"
                fullWidth 
                onClick={this.props.onClick}
                className={this.props.className}
            >
                Send password reset email
            </Button>
        )
    }
}

class ButtonLoadComponent extends React.Component {
    render() {
        return (
            <Button 
                variant="contained" 
                color="primary"
                fullWidth type="submit"
                disabled
            >
                Sending email... &nbsp;&nbsp;&nbsp;
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class LoginLinkComponent extends React.Component {
    render() {
        return (
            <Grid container
                style={{ textAlign: 'right' }} //force align to right 
            >
                <Grid item xs>
                    <Link href='/ui/StaffLogin' variant="body2">
                        Back to Login
                    </Link>
                </Grid>
            </Grid>
        )
    }
}

//The big component which encapsulates the above smaller components
class PageComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            input_username: '',
            error_username: false,

            loading: false,
            alert: false
        }
    }
    onChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value,
            error_username: false,
            alert: false,
            alert_msg: ''
        })
    }

    submitHandler = event => {
        //prevent the page from refreshing during submit action
        event.preventDefault()
        event.stopPropagation()

        if (this.state.input_username === '') {
            this.setState({
                error_username: this.state.input_username ? false : true,
            })
        }
        else {
            this.setState({
                error_username: false,
                loading: true
            })
            this.props.action(this.state.input_username)
        }
    }

    errorHandler = (msg) => {
        this.setState({
            loading: false,
            alert: true,
            alert_msg: msg
        })
    }

    render() {
        const { classes } = this.props

        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={8}
                className={classes.image} />
                <Grid item xs={12} sm={8} md={4} component={Paper}
                    elevation={6} square>
                    <div className={classes.paper}>
                        <img src={image} alt="Sabah Surveyors Board"
                            style={{ width: 160 }}/>
                        <br />
                        <Typography component="h1" variant="h2">
                            e-Connect LJS
                        </Typography>
                        <br />
                        <br />
                        <br />
                        <Typography component="h1" variant="h4">
                            Forgot Password
                        </Typography>
                        <Grid container style={{ textAlign: 'left' }} >
                            <Typography>
                                You will receive a link to create a new password via email.
                            </Typography>
                        </Grid>
                        <form className={classes.form} noValidate
                            onSubmit={this.submitHandler}>
                            <RequiredTextInput
                                error={this.state.error_username}
                                helperText={this.state.error_username ?
                                    "Username cannot be empty." :
                                    null}
                                onChange={this.onChangeHandler} />
                            <br />
                            <LoginLinkComponent />
                            <br />

                            {this.state.loading ?
                                <ButtonLoadComponent /> :
                                <ButtonIdleComponent onClick={this.submitHandler} />
                            }

                            {this.state.alert ?
                                <Alert severity="error">
                                    {this.state.alert_msg}
                            </Alert>
                                :
                                null}
                        </form>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles, { withTheme: true })(PageComponent);
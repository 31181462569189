import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import StaffPrivateRoute from './StaffPrivateRoute';
import SurveyorPrivateRoute from './SurveyorPrivateRoute';

// import Landing from '../Landing/Landing';


//----- LJS Staff pages -----
import StaffLogin from '../Staff/Auth/StaffLogin';
import StaffHome from '../Staff/Home/StaffHome';
import StaffPasswordReset from '../Staff/Password Reset/StaffPasswordReset';
import StaffResetPassword from '../Staff/Reset Password/StaffResetPassword';

import Staff_CadastralNewDashboardList from '../Staff/Dashboard List/Cadastral New/CadastralNewDashboardList';
import Staff_EngineeringNewDashboardList from '../Staff/Dashboard List/Engineering New/EngineeringNewDashboardList';
import Staff_PrivatisedNewDashboardList from '../Staff/Dashboard List/Privatised New/PrivatisedNewDashboardList';

import Staff_CadastralRegisteredDashboardList from '../Staff/Dashboard List/Cadastral Registered/CadastralRegisteredDashboardList';
import Staff_EngineeringRegisteredDashboardList from '../Staff/Dashboard List/Engineering Registered/EngineeringRegisteredDashboardList';
import Staff_PrivatisedRegisteredDashboardList from '../Staff/Dashboard List/Privatised Registered/PrivatisedRegisteredDashboardList';

import LS_CadastralSurveyJobPendingDashboardList from '../Surveyor/Dashboard List/LSStaff/Cadastral Survey Job Pending/CadastralSurveyJobPendingDashboardList';
import LS_CadastralSurveyJobPendingClaimDashboardList from '../Surveyor/Dashboard List/LSStaff/Cadastral Survey Job Pending Claim/CadastralSurveyJobPendingClaimDashboardList'
import LS_EngineeringSurveyJobPendingDashboardList from '../Surveyor/Dashboard List/LSStaff/Engineering Survey Job Pending/EngineeringSurveyJobPendingDashboardList';
import LS_PrivatisedSurveyJobPendingDashboardList from '../Surveyor/Dashboard List/LSStaff/Privatised Survey Job Pending/PrivatisedSurveyJobPendingDashboardList';
import LS_CadastralTechnicianPendingDashboardList from '../Surveyor/Dashboard List/LSStaff/Cadastral Technician Pending/CadastralTechnicianPendingDashboardList';
import LS_EngineeringTechnicianPendingDashboardList from '../Surveyor/Dashboard List/LSStaff/Engineering Technician Pending/EngineeringTechnicianPendingDashboardList';
import LS_ArticledGraduatePendingDashboardList from '../Surveyor/Dashboard List/LSStaff/Articled Graduate Pending/ArticledGraduatePendingDashboardList';
import LS_CPDPendingDashboardList from '../Surveyor/Dashboard List/LSStaff/CPD Pending/CPDPendingDashboardList';

import Staff_CadastralJobList from '../Staff/Survey Job/Cadastral/CadastralJobList';

import CadastralSurveyJobReview from '../Staff/Survey Job Review/Cadastral/CadastralSurveyJobReview';
import CadastralTableSummaryView from '../Staff/Survey Fee Tables/Cadastral/TableSummary/CadastralTableSummary';
import CadastralTable1View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table1/Table1';
import CadastralTable2View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table2/Table2';
import CadastralTable3View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table3/Table3';
import CadastralTable4View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table4/Table4';
import CadastralTable5View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table5/Table5';
import CadastralTable6View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table6/Table6';
import CadastralTable7View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table7/Table7';
import CadastralTable8View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table8/Table8';
import CadastralTable9View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table9/Table9';
import CadastralTable10View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table10/Table10';
import CadastralTable11View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table11/Table11';
import CadastralTable12View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table12/Table12';
import CadastralTable14View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table14/Table14';
import CadastralTable15View from '../Staff/Survey Fee Tables/Cadastral/SftCalculator/Table15/Table15';

import Staff_CadastralClaimList from '../Staff/Claim/Cadastral/CadastralClaimList';
import CadastralClaimReview from '../Staff/Claim Review/Cadastral/Claim/CadastralClaimReview';
import CadastralCancellationReview from '../Staff/Claim Review/Cadastral/Cancellation/CadastralCancellationReview';

import Staff_EngineeringJobList from '../Staff/Survey Job/Engineering/EngineeringJobList';

import EngineeringSurveyJobReview from '../Staff/Survey Job Review/Engineering/EngineeringSurveyJobReview';
import EngineeringTableSummaryView from '../Staff/Survey Fee Tables/Engineering/TableSummary/EngineeringTableSummary';
import EngineeringTable13AView from '../Staff/Survey Fee Tables/Engineering/SftCalculator/Table13A/Table13A';
import EngineeringTable13BView from '../Staff/Survey Fee Tables/Engineering/SftCalculator/Table13B/Table13B';
import EngineeringTable13CView from '../Staff/Survey Fee Tables/Engineering/SftCalculator/Table13C/Table13C';
import EngineeringTable13DView from '../Staff/Survey Fee Tables/Engineering/SftCalculator/Table13D/Table13D';
import EngineeringTable13EView from '../Staff/Survey Fee Tables/Engineering/SftCalculator/Table13E/Table13E';
import EngineeringTable13FView from '../Staff/Survey Fee Tables/Engineering/SftCalculator/Table13F/Table13F';
import EngineeringTable13GView from '../Staff/Survey Fee Tables/Engineering/SftCalculator/Table13G/Table13G';
import EngineeringTable14View from '../Staff/Survey Fee Tables/Engineering/SftCalculator/Table14/Table14';
import EngineeringTable15View from '../Staff/Survey Fee Tables/Engineering/SftCalculator/Table15/Table15';

import Staff_EngineeringClaimList from '../Staff/Claim/Engineering/EngineeringClaimList';
import EngineeringClaimReview from '../Staff/Claim Review/Engineering/Claim/EngineeringClaimReview';
import EngineeringCancellationReview from '../Staff/Claim Review/Engineering/Cancellation/EngineeringCancellationReview';

import PrivatisedNewApplicationReview from '../Staff/Survey Job Review/Privatised/New Application/PrivatisedNewApplicationReview';
import PrivatisedSurveyJobReview from '../Staff/Survey Job Review/Privatised/Existing/PrivatisedSurveyJobReview';
import Staff_PrivatisedJobList from '../Staff/Survey Job/Privatised/PrivatisedJobList';

import PaymentVoucherSearch from '../Staff/Survey Job Files/Payment Voucher/PaymentVoucherSearch';
import AdminChargeSearch from '../Staff/Survey Job Files/Admin Charge/AdminChargeSearch';
import PenyataBayaranSearch from '../Staff/Survey Job Files/Penyata Bayaran/PenyataBayaranSearch';

import RspJobStatus from '../Staff/Survey Job Status/Cadastral/RspJobStatus';
import EspJobStatus from '../Staff/Survey Job Status/Engineering/EspJobStatus';

import LicensedSurveyorRegister from '../Staff/Licensed Surveyor Register/LicensedSurveyorRegister';
import LicensedSurveyorList from '../Staff/Licensed Surveyor/LicensedSurveyorList';
import LicensedSurveyorView from '../Staff/Licensed Surveyor View/LicensedSurveyorView';
import LicensedSurveyorAdd from '../Staff/Licensed Surveyor Add/LicensedSurveyorAdd';

import LSStaffRegister from '../Staff/LS Staff Register/LSStaffRegister';
import LSStaffList from '../Staff/LS Staff/LSStaffList';
import LSStaffView from '../Staff/LS Staff View/LSStaffView';

import SurveyJob from '../Staff/Survey Job/SurveyJob';

import Staff_CadastralTechnicianList from '../Staff/Technician/Cadastral/CadastralTechnicianList';
import CadastralTechnicianReview from '../Staff/Technician Review/Cadastral/CadastralTechnicianReview';

import Staff_EngineeringTechnicianList from '../Staff/Technician/Engineering/EngineeringTechnicianList';
import EngineeringTechnicianReview from '../Staff/Technician Review/Engineering/EngineeringTechnicianReview';

import Staff_OldSTSTechnicianList from '../Staff/Technician/OldSTS/OldSTSTechnicianList';

import Staff_ArticledGraduateList from '../Staff/Articled Graduate/ArticledGraduateList';
import ArticledGraduateReview from '../Staff/Articled Graduate Review/ArticledGraduateReview';

import UserList from '../Staff/SystemParam/User/UserList';
import UserView from '../Staff/SystemParam/User View/UserView';
import UserAdd from '../Staff/SystemParam/User Add/UserAdd';

import Staff_CPDList from '../Staff/CPD/CPDList';
import CPDReview from '../Staff/CPD Review/CPDReview';
import Staff_CPDLogbook from '../Staff/CPD Logbook/CPDLogbook';

import TextToBank from '../Staff/TextToBank/TextToBank';

//Staff routes for Cadastral-Reports
import CadastralDeposit from '../Staff/Report/Cadastral/Deposit/CadastralDeposit';
import CadastralClaim from '../Staff/Report/Cadastral/Claim/CadastralClaim';
import CadastralLevy from '../Staff/Report/Cadastral/Levy/CadastralLevy';
import CadastralRefund from '../Staff/Report/Cadastral/Refund/CadastralRefund';

//Staff routes for Engineering-Reports
import EngineeringDeposit from '../Staff/Report/Engineering/Deposit/EngineeringDeposit';
import EngineeringClaim from '../Staff/Report/Engineering/Claim/EngineeringClaim';
import EngineeringLevy from '../Staff/Report/Engineering/Levy/EngineeringLevy';
import EngineeringRefund from '../Staff/Report/Engineering/Refund/EngineeringRefund';

//--- Surveyors pages ---
import SurveyorLogin from '../Surveyor/Auth/SurveyorLogin';
import SurveyorHome from '../Surveyor/Home/SurveyorHome';
import SurveyorPasswordReset from '../Surveyor/Password Reset/SurveyorPasswordReset';
import SurveyorResetPassword from '../Surveyor/Reset Password/SurveyorResetPassword';

//LS Staff route added on 07/10/2021
import LSStaffHome from '../Surveyor/Home/LSStaff/LSStaffHome';

import CadastralForm from '../Surveyor/Survey Job Application/Cadastral/CadastralForm/CadastralForm';
import CadastralTableList from '../Surveyor/Survey Job Application/Cadastral/CadastralTableList/CadastralTableList';

import CadastralTable1 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table1/Table1';
import CadastralTable2 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table2/Table2';
import CadastralTable3 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table3/Table3';
import CadastralTable4 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table4/Table4';
import CadastralTable5 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table5/Table5';
import CadastralTable6 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table6/Table6';
import CadastralTable7 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table7/Table7';
import CadastralTable8 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table8/Table8';
import CadastralTable9 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table9/Table9';
import CadastralTable10 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table10/Table10';
import CadastralTable11 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table11/Table11';
import CadastralTable12 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table12/Table12';
import CadastralTable13 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table13/Table13';
import CadastralTable14 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table14/Table14';
import CadastralTable15 from '../Surveyor/Survey Job Application/Cadastral/SftCalculator/Table15/Table15';

import CadastralTableSummary from '../Surveyor/Survey Job Application/Cadastral/CadastralTableSummary/CadastralTableSummary';
import CadastralSupportingDocumentForm from '../Surveyor/Survey Job Application/Cadastral/SupportingDocumentForm/SupportingDocumentForm';
import CadastralApplicationSummary from '../Surveyor/Survey Job Application/Cadastral/ApplicationSummary/ApplicationSummary';
import CadastralPaymentForm from '../Surveyor/Survey Job Application/Cadastral/PaymentForm/CadastralPaymentForm';

import CadastralJobList from '../Surveyor/Survey Job/Cadastral/CadastralJobList';

import CadastralJobView from '../Surveyor/Survey Job View/Cadastral/Summary/CadastralJobView';
import LS_CadastralTableSummaryView from '../Surveyor/Survey Job View/Cadastral/TableSummary/CadastralTableSummary';
import LS_CadastralTable1View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table1/Table1';
import LS_CadastralTable2View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table2/Table2';
import LS_CadastralTable3View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table3/Table3';
import LS_CadastralTable4View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table4/Table4';
import LS_CadastralTable5View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table5/Table5';
import LS_CadastralTable6View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table6/Table6';
import LS_CadastralTable7View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table7/Table7';
import LS_CadastralTable8View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table8/Table8';
import LS_CadastralTable9View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table9/Table9';
import LS_CadastralTable10View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table10/Table10';
import LS_CadastralTable11View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table11/Table11';
import LS_CadastralTable12View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table12/Table12';
import LS_CadastralTable14View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table14/Table14';
import LS_CadastralTable15View from '../Surveyor/Survey Job View/Cadastral/SftCalculator/Table15/Table15';

import CadastralClaimSelector from '../Surveyor/Claim Application/Cadastral/ClaimSelector/CadastralClaimSelector';
import CadastralClaimForm from '../Surveyor/Claim Application/Cadastral/ClaimForm/CadastralClaimForm';
import CadastralClaimList from '../Surveyor/Claim/Cadastral/CadastralClaimList';
import LS_CadastralClaimView from '../Surveyor/Claim View/Cadastral/Claim/CadastralClaimView';

import CadastralCancellationForm from '../Surveyor/Claim Application/Cadastral/CancellationForm/CadastralCancellationForm';
import LS_CadastralCancellationView from '../Surveyor/Claim View/Cadastral/Cancellation/CadastralCancellationView';

import EngineeringForm from '../Surveyor/Survey Job Application/Engineering/EngineeringForm/EngineeringForm';
import EngineeringTableList from '../Surveyor/Survey Job Application/Engineering/EngineeringTableList/EngineeringTableList';

import EngineeringTable13A from '../Surveyor/Survey Job Application/Engineering/SftCalculator/Table13A/Table13A';
import EngineeringTable13B from '../Surveyor/Survey Job Application/Engineering/SftCalculator/Table13B/Table13B';
import EngineeringTable13C from '../Surveyor/Survey Job Application/Engineering/SftCalculator/Table13C/Table13C';
import EngineeringTable13D from '../Surveyor/Survey Job Application/Engineering/SftCalculator/Table13D/Table13D';
import EngineeringTable13E from '../Surveyor/Survey Job Application/Engineering/SftCalculator/Table13E/Table13E';
import EngineeringTable13F from '../Surveyor/Survey Job Application/Engineering/SftCalculator/Table13F/Table13F';
import EngineeringTable13G from '../Surveyor/Survey Job Application/Engineering/SftCalculator/Table13G/Table13G';
import EngineeringTable14 from '../Surveyor/Survey Job Application/Engineering/SftCalculator/Table14/Table14';
import EngineeringTable15 from '../Surveyor/Survey Job Application/Engineering/SftCalculator/Table15/Table15';

import EngineeringTableSummary from '../Surveyor/Survey Job Application/Engineering/EngineeringTableSummary/EngineeringTableSummary';
import EngineeringSupportingDocumentForm from '../Surveyor/Survey Job Application/Engineering/SupportingDocumentForm/SupportingDocumentForm';
import EngineeringApplicationSummary from '../Surveyor/Survey Job Application/Engineering/ApplicationSummary/ApplicationSummary';
import EngineeringPaymentForm from '../Surveyor/Survey Job Application/Engineering/PaymentForm/EngineeringPaymentForm';

import EngineeringJobList from '../Surveyor/Survey Job/Engineering/EngineeringJobList';

import EngineeringJobView from '../Surveyor/Survey Job View/Engineering/Summary/EngineeringJobView';
import LS_EngineeringTableSummaryView from '../Surveyor/Survey Job View/Engineering/TableSummary/EngineeringTableSummary';
import LS_EngineeringTable13AView from '../Surveyor/Survey Job View/Engineering/SftCalculator/Table13A/Table13A';
import LS_EngineeringTable13BView from '../Surveyor/Survey Job View/Engineering/SftCalculator/Table13B/Table13B';
import LS_EngineeringTable13CView from '../Surveyor/Survey Job View/Engineering/SftCalculator/Table13C/Table13C';
import LS_EngineeringTable13DView from '../Surveyor/Survey Job View/Engineering/SftCalculator/Table13D/Table13D';
import LS_EngineeringTable13EView from '../Surveyor/Survey Job View/Engineering/SftCalculator/Table13E/Table13E';
import LS_EngineeringTable13FView from '../Surveyor/Survey Job View/Engineering/SftCalculator/Table13F/Table13F';
import LS_EngineeringTable13GView from '../Surveyor/Survey Job View/Engineering/SftCalculator/Table13G/Table13G';
import LS_EngineeringTable14View from '../Surveyor/Survey Job View/Engineering/SftCalculator/Table14/Table14';
import LS_EngineeringTable15View from '../Surveyor/Survey Job View/Engineering/SftCalculator/Table15/Table15';

import EngineeringClaimSelector from '../Surveyor/Claim Application/Engineering/ClaimSelector/EngineeringClaimSelector';
import EngineeringClaimForm from '../Surveyor/Claim Application/Engineering/ClaimForm/EngineeringClaimForm';
import EngineeringClaimList from '../Surveyor/Claim/Engineering/EngineeringClaimList';
import LS_EngineeringClaimView from '../Surveyor/Claim View/Engineering/Claim/EngineeringClaimView';

import EngineeringCancellationForm from '../Surveyor/Claim Application/Engineering/CancellationForm/EngineeringCancellationForm';
import LS_EngineeringCancellationView from '../Surveyor/Claim View/Engineering/Cancellation/EngineeringCancellationView';

import PrivatisedForm from '../Surveyor/Survey Job Application/Privatised/PrivatisedForm/PrivatisedForm';
import PrivatisedPaymentForm from '../Surveyor/Survey Job Application/Privatised/PaymentForm/PrivatisedPaymentForm';
import PrivatisedFormExist from '../Surveyor/Survey Job Application/Privatised/PrivatisedFormExist/PrivatisedFormExist';

import PrivatisedJobList from '../Surveyor/Survey Job/Privatised/PrivatisedJobList';

import PrivatisedArchiveJobView from '../Surveyor/Survey Job View/Privatised/Archive/PrivatisedArchiveJobView';
import PrivatisedJobView from '../Surveyor/Survey Job View/Privatised/Existing/PrivatisedJobView';

import CadastralTechnicianForm from '../Surveyor/Technician Application/Cadastral/CadastralTechnicianForm';
import CadastralTechnicianList from '../Surveyor/Technician/Cadastral/CadastralTechnicianList';
import LS_CadastralTechnicianView from '../Surveyor/Technician View/Cadastral/CadastralTechnicianView';
import CadastralTechnicianTerminateForm from '../Surveyor/Technician Termination/Cadastral/CadastralTechnicianTerminateForm';

import EngineeringTechnicianForm from '../Surveyor/Technician Application/Engineering/EngineeringTechnicianForm';
import EngineeringTechnicianList from '../Surveyor/Technician/Engineering/EngineeringTechnicianList';
import LS_EngineeringTechnicianView from '../Surveyor/Technician View/Engineering/EngineeringTechnicianView';
import EngineeringTechnicianTerminateForm from '../Surveyor/Technician Termination/Engineering/EngineeringTechnicianTerminateForm';

import ArticledGraduateForm from '../Surveyor/Articled Graduate Application/ArticledGraduateForm';
import ArticledGraduateList from '../Surveyor/Articled Graduate/ArticledGraduateList';
import LS_ArticledGraduateView from '../Surveyor/Articled Graduate View/ArticledGraduateView';
import ArticledGraduateTerminateForm from '../Surveyor/Articled Graduate Termination/ArticledGraduateTerminateForm';

import CPDForm from '../Surveyor/CPD Application/CPDForm';
import CPDList from '../Surveyor/CPD/CPDList';
import LS_CPDView from '../Surveyor/CPD View/CPDView';
import LS_CPDLogbook from '../Surveyor/CPD Logbook/CPDLogbook';

//LS routes for Reports
import CadastralJobStatement from '../Surveyor/Report/Cadastral/CadastralJobStatement';
import EngineeringJobStatement from '../Surveyor/Report/Engineering/EngineeringJobStatement';

class Router extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={SurveyorLogin}></Route>
                    <Route exact path="/ui/StaffLogin" component={StaffLogin}></Route>
                    <Route exact path="/ui/StaffPasswordReset" component={StaffPasswordReset}></Route>
                    <Route exact path="/ui/StaffResetPassword/:token" component={StaffResetPassword}></Route>
                    
                    <StaffPrivateRoute exact path="/ui/StaffHome" component={StaffHome} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/StaffCadastralJobList' component={Staff_CadastralJobList} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/StaffCadastralNewDashboardList' component={Staff_CadastralNewDashboardList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringNewDashboardList' component={Staff_EngineeringNewDashboardList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffPrivatisedNewDashboardList' component={Staff_PrivatisedNewDashboardList} action={this.props.action}></StaffPrivateRoute>
                    
                    <StaffPrivateRoute exact path='/ui/StaffCadastralRegisteredDashboardList' component={Staff_CadastralRegisteredDashboardList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringRegisteredDashboardList' component={Staff_EngineeringRegisteredDashboardList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffPrivatisedRegisteredDashboardList' component={Staff_PrivatisedRegisteredDashboardList} action={this.props.action}></StaffPrivateRoute>

                    {/* <StaffPrivateRoute exact path='/ui/StaffCadastralSurveyJobReview' component={CadastralSurveyJobReview} action={this.props.action}></StaffPrivateRoute>                     */}
                    <StaffPrivateRoute exact path='/ui/CadastralSurveyJobReview' component={CadastralSurveyJobReview} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTableSummaryView' component={CadastralTableSummaryView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable1View' component={CadastralTable1View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable2View' component={CadastralTable2View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable3View' component={CadastralTable3View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable4View' component={CadastralTable4View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable5View' component={CadastralTable5View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable6View' component={CadastralTable6View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable7View' component={CadastralTable7View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable8View' component={CadastralTable8View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable9View' component={CadastralTable9View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable10View' component={CadastralTable10View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable11View' component={CadastralTable11View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable12View' component={CadastralTable12View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable14View' component={CadastralTable14View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTable15View' component={CadastralTable15View} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/StaffCadastralClaimList' component={Staff_CadastralClaimList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/CadastralClaimReview' component={CadastralClaimReview} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/CadastralCancellationReview' component={CadastralCancellationReview} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/StaffEngineeringJobList' component={Staff_EngineeringJobList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/EngineeringSurveyJobReview' component={EngineeringSurveyJobReview} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTableSummaryView' component={EngineeringTableSummaryView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTable13AView' component={EngineeringTable13AView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTable13BView' component={EngineeringTable13BView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTable13CView' component={EngineeringTable13CView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTable13DView' component={EngineeringTable13DView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTable13EView' component={EngineeringTable13EView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTable13FView' component={EngineeringTable13FView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTable13GView' component={EngineeringTable13GView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTable14View' component={EngineeringTable14View} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTable15View' component={EngineeringTable15View} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/StaffEngineeringClaimList' component={Staff_EngineeringClaimList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/EngineeringClaimReview' component={EngineeringClaimReview} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/EngineeringCancellationReview' component={EngineeringCancellationReview} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/PrivatisedNewApplicationReview' component={PrivatisedNewApplicationReview} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/PrivatisedSurveyJobReview' component={PrivatisedSurveyJobReview} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/StaffPrivatisedJobList' component={Staff_PrivatisedJobList} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/PaymentVoucherSearch' component={PaymentVoucherSearch} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/AdminChargeSearch' component={AdminChargeSearch} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/PenyataBayaranSearch' component={PenyataBayaranSearch} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/RspJobStatus' component={RspJobStatus} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/EspJobStatus' component={EspJobStatus} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/LicensedSurveyorRegister' component={LicensedSurveyorRegister} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/LicensedSurveyorList' component={LicensedSurveyorList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/LicensedSurveyorView' component={LicensedSurveyorView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/LicensedSurveyorAdd' component={LicensedSurveyorAdd} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/LSStaffRegister' component={LSStaffRegister} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/LSStaffList' component={LSStaffList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/LSStaffView' component={LSStaffView} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/SurveyJob' component={SurveyJob} action={this.props.action}></StaffPrivateRoute>
                    
                    <StaffPrivateRoute exact path='/ui/StaffCadastralTechnicianList' component={Staff_CadastralTechnicianList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/CadastralTechnicianReview' component={CadastralTechnicianReview} action={this.props.action}></StaffPrivateRoute>
                    
                    <StaffPrivateRoute exact path='/ui/StaffEngineeringTechnicianList' component={Staff_EngineeringTechnicianList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/EngineeringTechnicianReview' component={EngineeringTechnicianReview} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/StaffOldSTSTechnicianList' component={Staff_OldSTSTechnicianList} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/StaffArticledGraduateList' component={Staff_ArticledGraduateList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/ArticledGraduateReview' component={ArticledGraduateReview} action={this.props.action}></StaffPrivateRoute>

                    {/* Staff-Reference_Parameters-User route added on 03/08/2022 */}
                    <StaffPrivateRoute exact path='/ui/UserList' component={UserList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/UserView' component={UserView} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path='/ui/UserAdd' component={UserAdd} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path='/ui/StaffCPDList' component={Staff_CPDList} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path="/ui/CPDReview" component={CPDReview} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path="/ui/StaffCPDLogbook" component={Staff_CPDLogbook} action={this.props.action}></StaffPrivateRoute>

                    <StaffPrivateRoute exact path="/ui/TextToBank" component={TextToBank} action={this.props.action}></StaffPrivateRoute>
                    {/* Staff-Cadastral-Report route added on 29/04/2024 */}
                    <StaffPrivateRoute exact path="/ui/Cadastral/Deposit" component={CadastralDeposit} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path="/ui/Cadastral/Claim" component={CadastralClaim} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path="/ui/Cadastral/Levy" component={CadastralLevy} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path="/ui/Cadastral/Refund" component={CadastralRefund} action={this.props.action}></StaffPrivateRoute>

                    {/* Staff-Engineering-Report route added on 24/07/2024 */}
                    <StaffPrivateRoute exact path="/ui/Engineering/Deposit" component={EngineeringDeposit} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path="/ui/Engineering/Claim" component={EngineeringClaim} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path="/ui/Engineering/Levy" component={EngineeringLevy} action={this.props.action}></StaffPrivateRoute>
                    <StaffPrivateRoute exact path="/ui/Engineering/Refund" component={EngineeringRefund} action={this.props.action}></StaffPrivateRoute>
                    
                    <Route exact path="/ui/SurveyorLogin" component={SurveyorLogin}></Route>
                    <Route exact path="/ui/SurveyorPasswordReset" component={SurveyorPasswordReset}></Route>
                    <Route exact path="/ui/SurveyorResetPassword/:token" component={SurveyorResetPassword}></Route>
                    <SurveyorPrivateRoute exact path="/ui/SurveyorHome" component={SurveyorHome} action={this.props.action}></SurveyorPrivateRoute>

                    {/* LS Staff route added on 07/10/2021 */}
                    <SurveyorPrivateRoute exact path="/ui/LSStaffHome" component={LSStaffHome} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path='/ui/LSCadastralSurveyJobPendingDashboardList' component={LS_CadastralSurveyJobPendingDashboardList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path='/ui/LSCadastralSurveyJobPendingClaimDashboardList' component={LS_CadastralSurveyJobPendingClaimDashboardList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path='/ui/LSEngineeringSurveyJobPendingDashboardList' component={LS_EngineeringSurveyJobPendingDashboardList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path='/ui/LSPrivatisedSurveyJobPendingDashboardList' component={LS_PrivatisedSurveyJobPendingDashboardList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path='/ui/LSCadastralTechnicianPendingDashboardList' component={LS_CadastralTechnicianPendingDashboardList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path='/ui/LSEngineeringTechnicianPendingDashboardList' component={LS_EngineeringTechnicianPendingDashboardList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path='/ui/LSArticledGraduatePendingDashboardList' component={LS_ArticledGraduatePendingDashboardList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path='/ui/LSCPDPendingDashboardList' component={LS_CPDPendingDashboardList} action={this.props.action}></SurveyorPrivateRoute>
                    
                    <SurveyorPrivateRoute exact path="/ui/CadastralForm" component={CadastralForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTableList" component={CadastralTableList} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/CadastralTable1" component={CadastralTable1} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable2" component={CadastralTable2} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable3" component={CadastralTable3} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable4" component={CadastralTable4} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable5" component={CadastralTable5} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable6" component={CadastralTable6} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable7" component={CadastralTable7} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable8" component={CadastralTable8} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable9" component={CadastralTable9} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable10" component={CadastralTable10} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable11" component={CadastralTable11} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable12" component={CadastralTable12} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable13" component={CadastralTable13} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable14" component={CadastralTable14} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable15" component={CadastralTable15} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/CadastralTableSummary" component={CadastralTableSummary} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralSupportingDocumentForm" component={CadastralSupportingDocumentForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralApplicationSummary" component={CadastralApplicationSummary} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralPaymentForm" component={CadastralPaymentForm} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/CadastralJobList" component={CadastralJobList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralJobView" component={CadastralJobView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTableSummaryView" component={LS_CadastralTableSummaryView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable1View" component={LS_CadastralTable1View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable2View" component={LS_CadastralTable2View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable3View" component={LS_CadastralTable3View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable4View" component={LS_CadastralTable4View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable5View" component={LS_CadastralTable5View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable6View" component={LS_CadastralTable6View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable7View" component={LS_CadastralTable7View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable8View" component={LS_CadastralTable8View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable9View" component={LS_CadastralTable9View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable10View" component={LS_CadastralTable10View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable11View" component={LS_CadastralTable11View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable12View" component={LS_CadastralTable12View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable14View" component={LS_CadastralTable14View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTable15View" component={LS_CadastralTable15View} action={this.props.action}></SurveyorPrivateRoute>
                    
                    <SurveyorPrivateRoute exact path="/ui/CadastralClaimSelector" component={CadastralClaimSelector} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralClaimForm" component={CadastralClaimForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralClaimList" component={CadastralClaimList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralClaimView" component={LS_CadastralClaimView} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/CadastralCancellationForm" component={CadastralCancellationForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralCancellationView" component={LS_CadastralCancellationView} action={this.props.action}></SurveyorPrivateRoute>
                    
                    <SurveyorPrivateRoute exact path="/ui/EngineeringForm" component={EngineeringForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTableList" component={EngineeringTableList} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13A" component={EngineeringTable13A} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13B" component={EngineeringTable13B} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13C" component={EngineeringTable13C} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13D" component={EngineeringTable13D} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13E" component={EngineeringTable13E} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13F" component={EngineeringTable13F} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13G" component={EngineeringTable13G} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable14" component={EngineeringTable14} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable15" component={EngineeringTable15} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/EngineeringTableSummary" component={EngineeringTableSummary} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringSupportingDocumentForm" component={EngineeringSupportingDocumentForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringApplicationSummary" component={EngineeringApplicationSummary} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringPaymentForm" component={EngineeringPaymentForm} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/EngineeringJobList" component={EngineeringJobList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringJobView" component={EngineeringJobView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTableSummaryView" component={LS_EngineeringTableSummaryView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13AView" component={LS_EngineeringTable13AView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13BView" component={LS_EngineeringTable13BView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13CView" component={LS_EngineeringTable13CView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13DView" component={LS_EngineeringTable13DView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13EView" component={LS_EngineeringTable13EView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13FView" component={LS_EngineeringTable13FView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable13GView" component={LS_EngineeringTable13GView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable14View" component={LS_EngineeringTable14View} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTable15View" component={LS_EngineeringTable15View} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/EngineeringClaimSelector" component={EngineeringClaimSelector} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringClaimForm" component={EngineeringClaimForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringClaimList" component={EngineeringClaimList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringClaimView" component={LS_EngineeringClaimView} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/EngineeringCancellationForm" component={EngineeringCancellationForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringCancellationView" component={LS_EngineeringCancellationView} action={this.props.action}></SurveyorPrivateRoute>
                    
                    <SurveyorPrivateRoute exact path="/ui/PrivatisedForm" component={PrivatisedForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/PrivatisedPaymentForm" component={PrivatisedPaymentForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/PrivatisedFormExist" component={PrivatisedFormExist} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/PrivatisedJobList" component={PrivatisedJobList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/PrivatisedArchiveJobView" component={PrivatisedArchiveJobView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/PrivatisedJobView" component={PrivatisedJobView} action={this.props.action}></SurveyorPrivateRoute>
                    
                    <SurveyorPrivateRoute exact path="/ui/CadastralTechnicianForm" component={CadastralTechnicianForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTechnicianList" component={CadastralTechnicianList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTechnicianView" component={LS_CadastralTechnicianView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CadastralTechnicianTerminateForm" component={CadastralTechnicianTerminateForm} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/EngineeringTechnicianForm" component={EngineeringTechnicianForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTechnicianList" component={EngineeringTechnicianList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTechnicianView" component={LS_EngineeringTechnicianView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/EngineeringTechnicianTerminateForm" component={EngineeringTechnicianTerminateForm} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/ArticledGraduateForm" component={ArticledGraduateForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/ArticledGraduateList" component={ArticledGraduateList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/ArticledGraduateView" component={LS_ArticledGraduateView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/ArticledGraduateTerminateForm" component={ArticledGraduateTerminateForm} action={this.props.action}></SurveyorPrivateRoute>

                    <SurveyorPrivateRoute exact path="/ui/CPDForm" component={CPDForm} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CPDList" component={CPDList} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CPDView" component={LS_CPDView} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/CPDLogbook" component={LS_CPDLogbook} action={this.props.action}></SurveyorPrivateRoute>

                    {/* <SurveyorPrivateRoute exact path="/ui/Claim/Select" component={PercentageSelector} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/Claim/Breakdown" component={ClaimBreakdown} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/Claim/Detail" component={ClaimDetail} action={this.props.action}></SurveyorPrivateRoute> */}

                    {/* <SurveyorPrivateRoute exact path="/ui/LS/CPD/Registration" component={CPDRegistration} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/LS/CPD/List" component={CPDList} action={this.props.action}></SurveyorPrivateRoute> */}
                    <SurveyorPrivateRoute exact path="/ui/Cadastral/JobStatement" component={CadastralJobStatement} action={this.props.action}></SurveyorPrivateRoute>
                    <SurveyorPrivateRoute exact path="/ui/Engineering/JobStatement" component={EngineeringJobStatement} action={this.props.action}></SurveyorPrivateRoute>

                </Switch>
            </BrowserRouter>
        )
    }
}

export default Router;
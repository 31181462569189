import React from 'react';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import DialogComponent from './Component/DialogComponent';
import PageComponent from './Component/PageComponent';


const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class StaffPasswordReset extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            dialogMsg: '',
            dialog: false,
            backdrop: false
        }
    }
    
    forgotPasswordHandler = (username) => {
        axios.post(Config() + '/authentication/staff/password/forgot',
        {
            username: username
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.setState({
                    dialogMsg: response.data['msg'],
                    dialog: true
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    this.ComponentUI.current.errorHandler("Invalid Username or Email")
                }

                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    closeDialog = () => {
        this.setState({ dialog: false })
    }

    goHomePage = () => {
        this.props.history.push({
            pathname: '/ui/StaffLogin'
        })
    }

    // goMainPage = () => {
    //     // sessionStorage.removeItem('form_id')
    //     window.location.reload()
    // }


    render() {
        return (
            <div>

                <DialogComponent
                    onClose={this.closeDialog}
                    dialogMessage={this.state.dialogMsg}
                    open={this.state.dialog}
                    onClick={this.goHomePage} />

                <PageComponent action={this.forgotPasswordHandler} ref={this.ComponentUI} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(StaffPasswordReset));


//--- DevNotes - Ejinn ---
//codes below holds the answer to have a simple link, from login page to password-reset page
//equivalent to <a> href, but need to be done in ReactJS way

/*
import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Section6Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Grid container
                direction="row"
                spacing={3}>

                <Grid item xs={12} sm={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Collapse in={this.props.visible}>
                        <Grow in={this.props.visible}>
                            <b>CPD Application</b>
                        </Grow>
                    </Collapse>
                </Grid>

                {!this.props.data.length ?
                    <Grid item xs={12} sm={12}>
                        <Collapse in={this.props.visible}>
                            <Grow in={this.props.visible}>
                                <Typography variant="h4">
                                    <b>It is empty here...</b>
                                </Typography>
                            </Grow>
                        </Collapse>
                    </Grid>
                    :
                    this.props.data.map(x => (
                        <Grid item xs={12} sm={3}
                            key={x.cpd_id}>
                            <Paper className={classes.paper}>
                                <Typography variant="subtitle1">
                                    <p>There is a new update.</p>
                                </Typography>
                                <p>CPD Category: <b>{x.category}</b></p>
                                <p>Description: <b>{x.description}</b></p>
                                <p>Reviewed by: <b>{x.reviewed_by}</b></p>
                                <p>Reviewed on: <b>{x.reviewed_at}</b></p>
                                <p>Status: <b>{x.status}</b></p>

                                <Button variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => this.props.action(x.cpd_id)}>
                                    View
                                            </Button>
                            </Paper>
                        </Grid>
                    ))
                }
            </Grid>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section6Component);
*/


/*
import Section6Component from './Component/Section6Component';


goCPDSummary = (cpd_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('cpd_id_view', cpd_id)
        this.props.history.push({
            pathname: '/ui/CPDView'
        })
}


<Section6Component
visible={this.state.visible}
data={this.state.section6_data}
action={this.goCPDSummary} />


*/
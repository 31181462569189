import React from 'react'

import { NumericFormat } from 'react-number-format';
import { CSVLink } from "react-csv";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
// Icon for Export buttons
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 1000
    },
    tablecell: {
        fontWeight: 'bold',
        width: '10%'
    }
})

const headers = [
    { label: "Date", key: "deposit_date" },
    { label: "LJS No.", key: "job_no" },
    { label: "Licensed Surveyor", key: "ls_name" },
    { label: "Ref. No.", key: "ls_reference" },
    { label: "Receipt", key: "receipt_no_t" },
    { label: "Receipt Date", key: "receipt_date" },
    { label: "Deposit Amount (RM)", key: "deposit_amount" }
  ];

class ExportCSVButtonComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled={this.props.disabled}
                className={this.props.className}
                startIcon={<GetAppRoundedIcon />}
                onClick={this.props.onClick}
            >
                Export CSV
            </Button>
        )
    }
}
 
class ExportPDFButtonComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled={this.props.disabled}
                className={this.props.className}
                startIcon={<PictureAsPdfRoundedIcon />}
                onClick={this.props.onClick}
            >
                Export PDF
            </Button>
        )
    }
}

class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
        this.csvLinkEl = React.createRef();
    }
    
    sortHandler = (clickedColumn) => () => {
        this.props.actionSort(clickedColumn)
    }

    onChangePage = (event, newPage) => {
        this.props.onChangePage(newPage)
    }

    onChangeRowsPerPage = (event) => {
        this.props.onChangeRowsPerPage(event.target.value)
    }


    getFileName = () => {
        let d = new Date();
        let dformat = `${d.getDate()}${d.getMonth()+1}${d.getFullYear()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}`;
        console.log("getCurrentDate : ", dformat);
        return "CadastralJobStatementReport_" + dformat + ".csv";
    }

    exportCSV = async () => {
        const data = this.props.data
        this.setState({ data: data }, () => {
            setTimeout(() => {
            this.csvLinkEl.current.link.click();
            });
        });
    }

    exportPDF = () => {
        this.props.action(this.props.data2, this.props.jobdeposit, 
            this.props.jobclaim, this.props.jobrefund)
    }

    render() {
        const { classes } = this.props
        
        let sumActualSurveyFee = 0.0
        // this.props.data2.map(x => ( sumActualSurveyFee += x.curr_surveyfee_balance ))
        
        const cellHeader = [
            {
                id: "seq_no",
                label: "No."
            },
            {
                id: "land_title",
                label: "Job Description / PT/Title No."
            },
            {
                id: "ls_reference",
                label: "LS Reference"
            },
            {
                id: "job_no",
                label: "LJS Reference"
            },
            {
                id: "rsp_esp_no",
                label: "RSP"
            },
            {
                id: "curr_stage",
                label: "Current Stage"
            },
            {
                id: "deposit_date",
                label: "Deposit Date"
            },
            {
                id: "deposit_amount",
                label: "Deposit Amount (RM)"
            },
            // {
            //     id: "receiveable_amount",
            //     label: "Receiveable Amount (RM)"
            // },
            {
                id: "claim_date",
                label: "Claim Date"
            },
            {
                id: "claim_amount",
                label: "Claim Amount (RM)"
            },
            {
                id: "refund",
                label: "Refund/Others (RM)"
            },
            {
                id: "refund_date",
                label: "Date"
            },
            {
                id: "surveyfee_balance",
                label: "Balance (RM)"
            }
        ]

        return (
            <Paper className={classes.paper}>
                {this.props.alert ?
                    <Alert severity="error">
                        {this.props.error_msg}
                    </Alert>
                    :
                    null}

                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {cellHeader.map(cellHeader => (
                                    <TableCell className={classes.tablecell}
                                        key={cellHeader.id}
                                        padding="default"
                                        align="center"
                                        sortDirection={this.props.column === cellHeader.id ? this.props.direction : null}                                        
                                    >
                                        <TableSortLabel
                                            active={this.props.column === cellHeader.id}
                                            direction={this.props.direction}
                                            onClick={this.sortHandler(cellHeader.id)}
                                        >
                                            {cellHeader.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        {!this.props.data.length ?
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center"
                                        colSpan={8}>
                                        No data to display.
                                </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {this.props.data.map(x => (
                                    <TableRow key={x.form_id}>
                                        <TableCell align="center">{x.seq_no}</TableCell>
                                        <TableCell align="center">{x.land_title}</TableCell>
                                        <TableCell align="center">{x.ls_reference}</TableCell>
                                        <TableCell align="center">{x.job_no}</TableCell>
                                        {/* <TableCell align="center">
                                            <Link href="#" 
                                            onClick={() => this.props.action(x.job_no)}>
                                                <b>{x.job_no}</b>
                                            </Link>
                                            </TableCell> */}
                                        <TableCell align="center">{x.rsp_no}</TableCell>
                                        {/* <TableCell align="center">{x.stage_desc}</TableCell> */}
                                        {x.curr_stage === 'APP' && x.status === 'Approved' ?
                                            <TableCell align="center">{x.stage_desc}<br/><b>Accepted</b></TableCell>
                                            :
                                        x.curr_stage === 'RSP' && x.status === 'Approved' ?
                                            <TableCell align="center">{x.stage_desc}<br/><b>Issued</b></TableCell>
                                            :
                                        x.curr_stage === 'CLM' && x.status === 'Approved' ?
                                            x.curr_percentage === 10 ? 
                                                <TableCell align="center">{x.stage_desc}<br/><b>{x.curr_percentage}% Adv Paid</b></TableCell> 
                                                :
                                                <TableCell align="center">{x.stage_desc}<br/><b>{x.curr_percentage}% Paid</b></TableCell>
                                            : x.curr_percentage > 0 ?
                                                <TableCell align="center">{x.stage_desc}<br/><b>{x.curr_percentage}% Paid</b></TableCell>
                                                :
                                                <TableCell align="center">{x.stage_desc}<br/><b>{x.status}</b></TableCell>
                                            }
                                        <TableCell align="center">{x.deposit_date}</TableCell>
                                        <TableCell align="right">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={x.amount_paid? x.amount_paid.toFixed(2) : '0.00' } 
                                                thousandSeparator=',' 
                                                decimalSeparator='.' 
                                            />
                                        </TableCell>
                                        {/* <TableCell align="center">
                                            <NumericFormat displayType="text" value='0.00' thousandSeparator=',' decimalSeparator='.' />
                                        </TableCell> */}
                                        <TableCell align="center">{x.claim_date}</TableCell>
                                        <TableCell align="right">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={x.claim_amount? x.claim_amount.toFixed(2) : '0.00' } 
                                                thousandSeparator=',' 
                                                decimalSeparator='.' 
                                            />
                                        </TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="right">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={x.curr_surveyfee_balance? x.curr_surveyfee_balance.toFixed(2) : '0.00' } 
                                                thousandSeparator=',' 
                                                decimalSeparator='.' 
                                            />
                                        </TableCell>
                                    </TableRow> 
                                ))}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TableContainer align="right">
                <TableBody>
                    <TableRow key="totalLsDeposit">
                        <TableCell align="right" style={{fontSize: 20, fontWeight: 'bold'}}>TOTAL BALANCE OF CLAIM :</TableCell>
                        <TableCell align="right" style={{fontSize: 20, fontWeight: 'bold'}}>
                            <NumericFormat 
                                displayType="text"
                                prefix='RM '
                                value={sumActualSurveyFee.toFixed(2)} 
                                thousandSeparator=',' 
                                decimalSeparator='.' 
                            />
                        </TableCell>
                    </TableRow> 

                </TableBody>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={this.props.count}
                    rowsPerPage={this.props.rowsPerPage}
                    page={this.props.page}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TableComponent);